import React from 'react'
import { RootComponent } from '../src/index'
import { ShellProps } from '../src/types/shell'
import { ThemeProvider } from '@veneer/theme'
import resources from '../src/assets/locale'
import useTheme from '@jarvis/react-hpx-support-shared/dist/hooks/useTheme'
import { DirectionProvider } from '@veneer/core/dist/esm/scripts/direction'
import { JarvisAnalyticsContextProvider } from '@jarvis/jweb-analytics'
import projectNames from '../src/configs/projectNames'
import {
  BaseScreenPath,
  Support,
  DefaultScreenName,
  DefaultScreenNameRootMFE
} from '../src/utils'

export default function Root(props) {
  const { v1 } = window.Shell as ShellProps
  const shell = window.Shell.v1
  const theme = useTheme()
  const time = new Date()
  console.log(
    `==================start root MFE: ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}:${time.getMilliseconds()}==================`
  )
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
      tabIndex={-1}
    >
      <JarvisAnalyticsContextProvider
        initialScreenData={{
          screenName: DefaultScreenNameRootMFE,
          screenPath: `${BaseScreenPath}/${Support}/`,
          activity: `${DefaultScreenName}-v01`
        }}
      >
        <DirectionProvider direction={theme.dir}>
          <ThemeProvider {...theme}>
            <RootComponent
              shell={shell}
              stack={props.stack}
              navigation={props.navigation}
              localization={v1?.localization}
              resources={resources}
              dir={theme.dir}
              themeProvider={theme}
            />
          </ThemeProvider>
        </DirectionProvider>
      </JarvisAnalyticsContextProvider>
    </section>
  )
}
