/* eslint-disable react-hooks/exhaustive-deps */
import {
  AccessToken,
  getAuthToken,
  logout as signOut,
  isLoggedIn,
  IsLoggedInResponse,
  subscribeLoginChangeEvent
} from '@jarvis/web-support-commons'
import { IProfile } from '@jarvis/web-support-commons/dist/methone'
import { CoptorAuth } from '@jarvis/web-support-commons/dist/methone/authorization'
import { useEffect, useState, useRef } from 'react'

const delay = 30 * 60 * 1000

const useAuth = () => {
  const [isSignedIn, setIsSignedIn] = useState<boolean | undefined>(false)
  const [auth, setAuth] = useState<CoptorAuth>()
  const [profile, setProfile] = useState(null)
  const middleware = useRef(null)
  const subscribe = useRef(null)
  const refreshInterval = useRef(null)
  const abortAuthPostCall = useRef(false)

  useEffect(() => {
    const authListener = async () => {
      subscribe.current = subscribeLoginChangeEvent(eventHandler)
      refreshInterval.current = setInterval(() => refreshToken(), delay)
    }

    const eventHandler = (event) => {
      console.log('handler - subscriber login')
      const accountInfo = event?.eventData?.currentAccount
      if (accountInfo?.AccountId) {
        setAuthToken(false)
      } else {
        setIsSignedIn(false)
        setAuth(null)
        setProfile(null)
      }
    }

    const unsubscribe = () => {
      subscribe?.current?.remove()
      clearInterval(refreshInterval?.current)
    }

    authListener()

    window.addEventListener('beforeunload', unsubscribe)
    return () => {
      window.removeEventListener('beforeunload', unsubscribe)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchToken = async () => {
      const response = await isLoggedIn()
      if (response?.value != isSignedIn) setIsSignedIn(response?.value)
      if (response?.value) setAuthToken()
    }
    fetchToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (middleware?.current && auth) middleware?.current({ auth })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [middleware?.current, auth])

  const callMiddleware = (callback) => {
    middleware.current = callback
  }

  const setAuthToken = async (resetAbort = true) => {
    if (resetAbort) abortAuthPostCall.current = false
    const accessToken: AccessToken | undefined = await getAuthToken()
    if (accessToken?.tokenValue) {
      if (accessToken?.tokenValue != auth?.token) {
        const auth: CoptorAuth = {
          token: accessToken?.tokenValue,
          type: 'COPTOR'
        }
        setAuth(auth)
        if (middleware?.current) middleware?.current({ auth })
        getProfile(accessToken.account)
        await checkIsLoggedIn()
        return abortAuthPostCall.current ? null : auth
      }
      return auth
    }
    setIsSignedIn(false)
    return accessToken?.tokenValue
  }

  const getProfile = (account) => {
    const profile: IProfile = {
      LastName: account.familyName,
      FirstName: account.givenName,
      Email: account.emailAddress,
      FaultItemList: [],
      EmailOffers: null,
      City: null,
      PrimaryUse: null,
      ActiveHealth: null,
      Country: null,
      Language: null,
      Id: account.accountId,
      Company: null,
      Addresses: [],
      DisplayName: null,
      Gender: null,
      UserName: null,
      PhoneNumbers: [],
      SmartFriend: null
    }
    setProfile(profile)
  }

  const refreshToken = async () => {
    const isSignedIn = await checkIsLoggedIn()
    if (isSignedIn) return await setAuthToken()
    return null
  }

  const checkIsLoggedIn = async () => {
    const response: IsLoggedInResponse | undefined = await isLoggedIn()
    if (response?.value != isSignedIn) setIsSignedIn(response?.value)
    return response?.value
  }

  const checkAuth = async () => {
    const _isLoggedIn = await checkIsLoggedIn()
    if (!(_isLoggedIn && auth)) return await setAuthToken()
    return auth
  }

  const logout = async () => {
    setIsSignedIn(false)
    setAuth(null)
    await signOut()
  }

  const abortAuthCall = () => {
    abortAuthPostCall.current = !isSignedIn
  }

  return {
    setAuthToken,
    refreshToken,
    checkIsLoggedIn,
    checkAuth,
    setIsSignedIn,
    isSignedIn,
    auth,
    profile,
    logout,
    callMiddleware,
    middleware,
    abortAuthCall
  }
}

export default useAuth
