import {
  Account,
  deviceInfo,
  getOIDs,
  DeviceInfoRequest,
  WarrantyCache,
  SubscriptionInfo
} from '@jarvis/web-support-commons'
import { WhenJWebReady } from '@jarvis/jweb-core'
import { Stack } from 'src/types/stratus'
import getConfig, { getEnv } from '../client/config'
import { isEmpty } from '../vaJsonParser'
import './focusWithin'
import './capitalize'
export {
  getDevicesMock,
  getHostPCInfo,
  formatDeviceList,
  subscirbeStratusEvent,
  removeDeviceList,
  getImage
} from './device'
import ResizeObserver from 'resize-observer-polyfill'

declare global {
  interface Window {
    ResizeObserver: any
  }
}
window.ResizeObserver = ResizeObserver

export type IRequestBody = {
  sn: string | ''
  pn: string | ''
  country: string | ''
  language: string | ''
  appVersion: string | ''
  platForm: string | ''
  query?: string | ''
  isSignedIn?: boolean | false
  tokenValue?: string | ''
  userAccount?: Account | null
  stack: Stack
  osVersion: string | ''
  device?: any
}

/**
 * Format productNumber
 * @param productNumber device's productNumber
 * @return new format productNumber
 */
function formatProductNumber(productNumber: string) {
  let newFormatPN = productNumber
  const indexOfPN = newFormatPN.lastIndexOf('#')
  if (indexOfPN !== -1) {
    newFormatPN = productNumber.substring(0, indexOfPN)
  }
  return newFormatPN
}

/**
 * Get device OIDs
 * @param productNumber productNumber
 * @param serialNumber productNumber
 * @param stack STG, PRO etc... environments
 * @returns device OIDs
 */
async function getProductOIDsInfo(
  productNumber: string,
  serialNumber: string,
  stack: Stack
) {
  const localeApp = await deviceInfo.getLocale()
  if (localeApp) {
    const localStr = localeApp.split('-')
    const appCountry = localStr.length > 2 ? localStr[2] : localStr[1]
    const appLanguage = localStr[0]
    const localeStr = appLanguage
      .toLowerCase()
      .concat(`-${appCountry.toUpperCase()}`)
    const deviceInfo = await getOIDInfo(
      formatProductNumber(productNumber),
      serialNumber,
      localeStr,
      stack
    )
    return deviceInfo
  }
}

/**
 * Get device OIDs(check from cache first)
 * @param productNumber productNumber
 * @param serialNumber serialNumber
 * @param locale en-US, zh-CN
 * @param stack STG, PRO environments
 */
async function getOIDInfo(
  productNumber: string,
  serialNumber: string,
  localeStr: string,
  stack: Stack
) {
  const ENV = getEnv(stack)
  const Config = await getConfig()
  const params: DeviceInfoRequest = {
    productNumber: productNumber,
    locale: localeStr,
    apiKey: Config.API_KEY,
    basePath: Config[ENV]?.API_URL
  }
  try {
    return await getOIDs(serialNumber, params)
  } catch (error) {
    new Error('getOIDs error' + error)
  }
}

/**
 * Get launch url
 * @param request body params
 * @param optin
 * @returns launch url
 */
export const requestParams = async (
  request: IRequestBody,
  optin: boolean | string
) => {
  let productNameOid = ''
  let productOid = ''
  let productSeriesOID = ''
  if (request?.device?.IsHostPC) {
    const productOIDsInfo = await getProductOIDsInfo(
      request?.pn,
      request?.sn,
      request?.stack
    )
    productNameOid = productOIDsInfo?.ProductNameOID
    productOid = productOIDsInfo?.ProductNumberOID
    productSeriesOID = productOIDsInfo?.ProductSeriesOID
  } else {
    productNameOid = request?.device?.ProductNameOID
    // Remote device hasn't ProductNumberOID field, so give it empty
    productOid = ''
    productSeriesOID = request?.device?.ProductSeriesOID
  }
  const credential: any = {
    LaunchingAppVersion: request?.appVersion,
    Conversation: {
      EventSource: '',
      Query: request?.query,
      PageId: ''
    },
    Product: {
      OS: request?.osVersion,
      ProductNameOid: productNameOid,
      ProductNumber: formatProductNumber(request?.pn),
      ProductOid: productOid,
      ProductSeriesOID: productSeriesOID
    }
  }

  //The serial number should only be sent to VA API when the warranty is opt-in.
  // If opt-in is false will be send, otherwise will not be send
  if (optin) {
    credential.Product = {
      SerialNumber: request?.sn,
      ...credential.Product
    }
  }

  if (
    request.isSignedIn &&
    !isEmpty(request?.tokenValue) &&
    !isEmpty(request?.userAccount)
  ) {
    credential.Credentials = {
      OneCloudAccessToken: request?.tokenValue,
      FirstName: request?.userAccount?.givenName
    }
  }
  return credential
}

const FORMAT = 'AUID_'

/**
 * Format automation info
 * @param text automation info
 * @param feature is optional, module name, such as Support, PCAudio, PCConnect etc... default value is Support
 * @returns automation info append with FORMAT
 */
export const formatAutoId = (text: string, feature = 'Support'): string => {
  return `${FORMAT.concat(feature).concat('_').concat(text)}`
}

export const JARVIS_ENVIRONMENT = {
  WEB: 'web',
  IOS: 'ios',
  ANDROID: 'android',
  WINDOWS: 'windows'
}

/**
 * Ensure platform is web or isNative is native
 * @returns object
 * @refer https://pages.github.azc.ext.hp.com/jarvis/jweb/1.4.1/docs/jarvis-plugins
 */
export const ensureWeb = async () => {
  const res = await WhenJWebReady
  return {
    platform: res.JWeb.platform,
    isNative: res.JWeb.isNative
  }
}

// Added event trigger on page load to replace skeleton
export const SHOW_PAGE_EVENT_NAME = 'showSupportWelcomePage'
export const PUBLISHER_ID = 'com.hp.jarvis.hpxsupportpage.app.publisher'
export const Support = 'Support'
export const BaseScreenPath = '/HpxWinClient'
export const DefaultScreenName = 'SupportHome'
export const DefaultScreenNameRootMFE = 'SupportRoot'
export const DeviceScreenName = 'DeviceSupport'

export const TestIDPrefix = 'Supp'
export const ContainerComponent = 'Container'
export const MinButtonComponent = 'MinButton'
export const MaxButtonComponent = 'MaxButton'
export const CloseButtonComponent = 'CloseButton'
export const SHOW_VA_MODAL_EVENT = 'showRNVAModal'

export function genTestId(
  moduleId: string,
  componentId: string,
  suffix?: string
): string {
  if (moduleId.length > 4) {
    moduleId = moduleId.slice(0, 4)
  }

  if (componentId.length > 28) {
    componentId = componentId.slice(0, 28)
  }
  // JWebTrackId should not be longer than 32 characters because of simpleUi event schema constraints for the 'componentId' field
  const testJWebId = `${moduleId}${componentId}`

  return testJWebId
}

const warrantyInfo: WarrantyCache = {
  status: '',
  statusCode: 0,
  userCheckDate: '',
  warrantyStartDate: '',
  warrantyEndDate: '',
  carepack: false
}

const subscriptionInfo: SubscriptionInfo = {
  userCheckDate: '',
  subscriptionStatus: '',
  subscriptionId: '',
  subscriptionStartDate: ''
}

export const initialState = {
  subscriptionInfo,
  warrantyInfo
}
