/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Header, Row, BackButton, LinearGradientHeader } from '../styles'
import IconChevronLeft from '@veneer/core/dist/esm/scripts/icons/icon_chevron_left'
import IconSupport from '@veneer/core/dist/esm/scripts/icons/icon_support'
import useContextAPI from '../../../hooks/useContextAPI'
import { formatAutoId } from '../../../utils'

const NavigationHeader = ({ showBack, onBack, t, navigation }): JSX.Element => {
  const [navBarTitle, setNavBarTitle] = useState('')
  const { environment } = useContextAPI()
  const { pathname } = useLocation()

  useEffect(() => {
    const navBarTitle = navigation?.location?.state?.navBarTitle
    setNavBarTitle(navBarTitle ? t(navBarTitle) : t('common.support'))
    // TODO: Will be remove if others route would be defined in future
    // const pathDeviceDetail = pathname?.includes('/device')
    // const pathGuidedTroubleshooting = pathname?.includes(
    //   '/guidedtroubleshooting'
    // )
    // const pathVirtualAssistant = pathname?.includes('/virtualassistant')
    // const pathAgent = pathname?.includes('/speaktoagent')
    // const pathChat = pathname?.includes('/chatwithagent')

    // if (pathDeviceDetail && environment?.isMobile) {
    //   if (pathAgent && environment?.isMobile) {
    //     setNavBarTitle(t('speaktoagent'))
    //   } else if (pathChat && environment?.isMobile) {
    //     setNavBarTitle(t('chatwithagent'))
    //   } else {
    //     setNavBarTitle(t('common.deviceSupport'))
    //   }
    // } else if (pathGuidedTroubleshooting && environment?.isMobile) {
    //   setNavBarTitle(t('common.guidedTroubleshooting'))
    // } else if (pathVirtualAssistant && environment?.isMobile) {
    //   setNavBarTitle(t('common.virtualAssistant'))
    // } else {
    //   setNavBarTitle(t('common.support'))
    // }
  }, [pathname, environment?.isMobile])

  return (
    <>
      {environment?.isMobile ? (
        <>
          <Header>
            <Row>
              <BackButton
                aria-label="back"
                onClick={onBack}
                appearance="ghost"
                className="ghost backButton"
                leadingIcon={<IconChevronLeft size={50} color="colorWhite" />}
              />
              <h4
                className="title-small"
                data-testid="support-header"
                tabIndex={-1}
                id={formatAutoId('support-header')}
              >
                {navBarTitle}
              </h4>
            </Row>
          </Header>
          <LinearGradientHeader />
        </>
      ) : (
        <Header>
          <Row>
            {showBack && (
              <BackButton
                id={formatAutoId('back-button')}
                aria-label="back"
                onClick={onBack}
                small
                appearance="ghost"
                className="ghost backButton"
                leadingIcon={<IconChevronLeft size={40} color="colorWhite" />}
              />
            )}
            <IconSupport className="support" size={40} color="colorWhite" />
            <h4
              className="title-small"
              data-testid="support-header"
              tabIndex={-1}
              id={formatAutoId('support-header')}
            >
              {t('common.support')}
            </h4>
          </Row>
        </Header>
      )}
    </>
  )
}

export default memo(NavigationHeader)
