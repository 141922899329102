import { useEffect, useRef } from 'react'

function useDidMount() {
  const isMountRef = useRef(false)

  useEffect(() => {
    isMountRef.current = true
  }, [])

  return isMountRef.current
}

export default useDidMount
