import getConfig, { getEnv } from './config'
import {
  DeviceInfoRequest,
  getDeviceId as getDeviceIdWin,
  getDeviceImageWin,
  confirmWarrantyJWeb,
  WarrantyConfirmRequest,
  getWarrantyInfoJWeb,
  WarrantyRequest,
  getMethoneDeviceList,
  startStratusAsyncJob,
  refreshDeviceList,
  editNickName,
  getOIDs,
  AWSConfig,
  getStratusDeviceList,
  getDeviceListFromCache
} from '@jarvis/web-support-commons'
import { getLocale } from '@jarvis/web-support-commons/dist/deviceInfo/hostInfo'
import { useEffect, useRef } from 'react'
import { get, set } from '@jarvis/web-support-commons/dist/asyncStorage'
import {
  Key_StratusLoadComplete,
  Key_SupportDeviceList
} from '@jarvis/web-support-commons/dist/constants'

const controller = new AbortController()

const useMethoneWin = ({ stack, auth }) => {
  const config = useRef(null)

  useEffect(() => {
    ;(async () => {
      const ENV = getEnv(stack)
      const Config = await getConfig()
      setConfig({
        apiKey: Config.API_KEY || '',
        basePath: Config[ENV]?.API_URL,
        auth: auth
      })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (auth && config) setAuth(auth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, config])

  const setAuth = (auth) => {
    if (config)
      setConfig({
        ...config.current,
        auth
      })
  }

  const setConfig = (param) => {
    config.current = param
  }

  const getDeviceImage = async (productNumber, serialNumber) => {
    const param: DeviceInfoRequest = {
      productNumber: productNumber.split('#')[0],
      locale: await getLocale(),
      ...config?.current
    }
    return await getDeviceImageWin(serialNumber, param)
  }

  const getOID = async (productNumber, serialNumber) => {
    const param: DeviceInfoRequest = {
      productNumber: productNumber.split('#')[0],
      locale: await getLocale(),
      ...config?.current
    }
    return await getOIDs(serialNumber, param)
  }

  const getDeviceId = async (serialNumber) => {
    return await getDeviceIdWin(serialNumber, {
      ...config?.current
    })
  }

  const confirmWarranty = async ({
    SerialNumber,
    ProductNumber,
    Locale,
    CountryCode
  }) => {
    const request: WarrantyConfirmRequest = {
      ...config.current,
      CountryCode,
      Locale,
      SerialNumber,
      ProductNumber
    }
    return await confirmWarrantyJWeb(request)
  }

  const getWarrantyInfo = async ({
    CountryCode,
    Locale,
    bornOnDate = null,
    SerialNumber,
    ProductNumber,
    clientId = null,
    UTCOffset = null,
    userOptin = null
  }) => {
    const request: WarrantyRequest = {
      ...config?.current,
      CountryCode,
      Locale,
      clientId,
      bornOnDate,
      SerialNumber,
      ProductNumber,
      UTCOffset,
      userOptin
    }
    console.log(`getWarrantyInfo -- request --------`)
    const response = await getWarrantyInfoJWeb(request)
    console.log(`getWarrantyInfo -- repsone --------`)
    console.log(response)
    return response
  }

  const refershDevices = async (deviceListEnabled, jobStatusCheckInterval) => {
    const request = {
      ...config.current,
      auth: config?.current?.auth?.token || auth?.token
    }
    const response = await refreshDeviceList(
      request,
      deviceListEnabled,
      jobStatusCheckInterval,
      controller
    )
    return response
  }

  const editDeviceNickName = async (deviceId, nickname) => {
    const request = {
      ...config.current,
      auth: config?.current?.auth?.token || auth?.token
    }
    return await editNickName(request, deviceId, nickname)
  }

  const startAsyncJob = async () => {
    const request = {
      ...config.current,
      auth: config?.current?.auth?.token || auth?.token
    }
    const response = await startStratusAsyncJob(request, controller)
    return response
  }

  const getDevices = async ({
    ignoreDeviceChanges,
    enableStratusCall,
    jobStartViaMFE,
    jobStatusCheckInterval,
    cache = false
  }) => {
    if (cache) {
      const response = await getDevicesCache()
      return JSON.parse(response)
    }
    const request = {
      ...config.current,
      auth: config?.current?.auth?.token || auth?.token
    }
    const response = await getMethoneDeviceList(
      request,
      ignoreDeviceChanges,
      enableStratusCall,
      jobStartViaMFE,
      jobStatusCheckInterval,
      controller
    )
    return response
  }

  const getStratusDevices = async () => {
    const basePath =
      (await get('@env/STRATUS_APIURL')) ?? 'https://us1.api.ws-hp.com/'

    const request: AWSConfig = {
      basePath: basePath,
      auth: config?.current?.auth?.token || auth?.token
    }

    const deviceList = await getStratusDeviceList(request)
    if (deviceList) {
      // Set device list to cache
      await set('@hp-support/device-info/devices', JSON.stringify(deviceList))
    }
  }

  const getDevicesCache = async () => {
    try {
      const response = await get(Key_SupportDeviceList)
      return JSON.parse(response)
    } catch {
      return null
    }
  }

  const getIsStartusLoadCompleted = async (deviceListEnabled) => {
    if (deviceListEnabled) {
      return await get(Key_StratusLoadComplete)
    }
    return true
  }

  const getDevicesFromCache = async () => {
    return await getDeviceListFromCache()
  }

  return {
    getDeviceImage,
    getDeviceId,
    setAuth,
    confirmWarranty,
    getWarrantyInfo,
    getDevices,
    getStratusDevices,
    getDevicesCache,
    refershDevices,
    editDeviceNickName,
    getIsStartusLoadCompleted,
    getOID,
    getDevicesFromCache,
    startAsyncJob
  }
}

export default useMethoneWin
