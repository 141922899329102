import React, { useEffect, useState } from 'react'
import { Section } from './styles'
import useContent from '../../../hooks/useContent'
import createDOMPurify from 'dompurify'
import { launchUrlFromCommand } from '@jarvis/web-support-commons'
import { formatAutoId } from '../../../utils'
const DOMPurify = createDOMPurify(window)

const config = {
  ALLOW_UNKNOWN_PROTOCOLS: true
}

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  switch (node.tagName.toLowerCase()) {
    case 'h2':
      node.setAttribute('class', 'title-small')
      node.setAttribute('id', formatAutoId('content-title'))
      break
    case 'h3':
    case 'h4':
      node.setAttribute('class', 'subtitle-regular')
      break
    case 'p':
    case 'li':
      node.setAttribute('class', 'caption')
      break
  }
})

const ExternalContent = (props) => {
  const { geExternalHTML } = useContent()
  const [html, setHtml] = useState<string>()
  const { setLoading, device } = props

  const clickEvent = (e) => {
    if (e?.target['tagName']?.toLowerCase() === 'a') {
      try {
        e.preventDefault()
        const href = e?.target.getAttribute('href')
        const context = href.replace(/\//gi, '').split(':')[1]
        launchUrlFromCommand(
          `"HPSALauncher.exe" ${context.toLowerCase()} %serialNumber% /UseCCC`,
          device
        )
        // eslint-disable-next-line no-empty
      } catch {}
    }
  }

  useEffect(() => {
    if (html) setLoading('externalContent', false)
    else setLoading('externalContent', true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [html])

  useEffect(() => {
    const fetchUrl = async () => {
      const html = await geExternalHTML({ content: props.content })
      setHtml(html?.default)
      setLoading('externalContent', false)
    }
    fetchUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.content])

  return (
    <>
      {html && (
        <Section>
          <div
            onClick={(e) => clickEvent(e)}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(html, config)
            }}
          ></div>
        </Section>
      )}
    </>
  )
}

export default ExternalContent
