/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Modals, {
  ModalMinimized
} from '@jarvis/react-hpx-support-shared/dist/components/Modals/index'
import useModal from '@jarvis/react-hpx-support-shared/dist/hooks/useModal'
import { Section } from '../VirtualAssistant/VAChat/styles'
import SupportHomeVA from '../VirtualAssistant/SupportHomeVA'
import SupportDeviceDetailVA from '../VirtualAssistant/SupportDeviceDetailVA'
import { VAType, JSType } from './types'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import useContextAPI from '../../hooks/useContextAPI'
import ExternalContent from './External'
import { FloatView } from '../Dashboard/styles'
import useVA from '../../hooks/useVA'
import useDidMount from '../../hooks/useDidMount'
import { JARVIS_ENVIRONMENT } from '../../utils'

const ModalLoader = ({ shell, stack }) => {
  const { authProvider } = useContextAPI()
  const { auth } = authProvider

  const {
    modals,
    openModal,
    setLoading,
    closeAllModal,
    updateModal,
    setResize,
    closeModal
  } = useModal()
  const { confirmDialog, closeDialog } = useVA()
  const { t } = useI18n()
  const didMount = useDidMount()

  useEffect(() => {
    if (auth === null && didMount) {
      closeDialog?.()
      closeAllModal?.()
    }
  }, [auth])

  const ModalWidget = () => {
    const getModalLength = () => {
      const arr = Object.keys(modals)
      return arr.length
    }

    const getModalMinmisedLength = () => {
      let size = 0
      for (const key in modals) {
        if (modals[key].resize) size = size + 1
      }
      return size
    }
    const getTitle = (type, title) => {
      return modals[VAType.VAFlag]?.config?.[type]?.isOpen
        ? t('common.hpVirtualAssistant')
        : title
    }
    const resetModal = (type, key) => {
      updateModal(VAType.VAFlag, {
        isOpen: true,
        sessionStatus: true,
        width: '75%',
        resize: true,
        config: {
          ...modals?.VA?.config,
          [key]: { isOpen: false }
        },
        category: {
          type: type,
          contextTitle: t('common.hpVirtualAssistant')
        }
      })
    }
    const getSessionStatus = (type, key) => {
      console.log(modals[key]?.config?.[type]?.isOpen)
      return key == VAType.VAFlag && modals[key]?.config?.[type]?.isOpen
    }

    const resetVAModal = (okClick, callback, key) => {
      for (const item in modals[key]?.config) {
        if (
          modals[key]?.config[item].isOpen &&
          modals[key].category.type != item
        ) {
          resetModal(item, modals[key].category.type)
          okClick(key)
          return
        }
      }
      callback?.(key)
    }

    const okClick = (key) => {
      if (!modals[key]?.resize) {
        setResize(key, true)
      }
    }

    const cancelClick = (key) => {
      closeModal(key)
    }

    const vaCloseCallback = (key) => {
      if (key == VAType.VAFlag) {
        const modal = modals[key]
        const type = modal?.category?.type
        const config = modal?.config
        if (config?.[type]?.isOpen) {
          confirmDialog({
            callbackYes: () => okClick(key),
            callbackNo: () => resetVAModal(okClick, cancelClick, key)
          })
        } else {
          resetVAModal(okClick, closeModal, key)
        }
      }
    }

    const getModals = () => {
      return Object.keys(modals)
    }

    const commonVAProps = () => {
      return {
        stack: stack,
        localization: shell?.localization,
        setLoading: setLoading,
        openModal: openModal,
        updateModal: updateModal,
        auth: auth,
        closeAllModal: closeAllModal
      }
    }
    return (
      <>
        {modals &&
          getModals()?.map((key) => {
            return (
              <ModalItem
                modals={modals}
                key={key}
                name={key}
                getTitle={getTitle}
                getSessionStatus={getSessionStatus}
                vaCloseCallback={vaCloseCallback}
                getModalLength={getModalLength}
                commonVAProps={commonVAProps}
                setLoading={setLoading}
                closeModal={closeModal}
              ></ModalItem>
            )
          })}
        <FloatView size={getModalMinmisedLength()}>
          {modals &&
            getModals()?.map((key, index) => {
              const modal = modals?.[key]
              const { category } = modal || {}
              return (
                <>
                  {modal?.resize && (
                    <ModalMinimized
                      type={category?.type}
                      resize={modal?.resize}
                      isResizable={modal?.isResizable}
                      modalTitle={
                        modal?.modalTitle
                          ? modal?.modalTitle
                          : t('common.myHPVA')
                      }
                      setResize={setResize}
                      closeModal={closeModal}
                      key={index}
                      modal={key}
                      title={getTitle(category?.type, category?.contextTitle)}
                      subTitle={category?.contextSubTitle}
                      closeCallback={
                        key === VAType.VAFlag ? vaCloseCallback : closeModal
                      }
                      i18n={{
                        minimize: t('common.minimizeLowerCase'),
                        maximize: t('common.maximizeLowerCase'),
                        close: t('common.closeLowerCase')
                      }}
                    ></ModalMinimized>
                  )}
                </>
              )
            })}
        </FloatView>
      </>
    )
  }
  return <>{ModalWidget()}</>
}

const DynamicComponent = ({ component, props }) => {
  const Component = component
  const { methoneProvider, authProvider } = useContextAPI()
  for (const key in props) {
    switch (key) {
      case 'MethoneClient':
        props.MethoneClient = methoneProvider.MethoneClient
        break
      case 'MethoneWinClient':
        props.MethoneClient = methoneProvider.MethoneWinClient
        break
      case 'authProvider':
        props.authProvider = authProvider
        break
    }
  }
  return (
    <>{props ? <Component {...props}></Component> : <Component></Component>}</>
  )
}

const ModalItem = ({
  modals,
  name,
  getTitle,
  getSessionStatus,
  vaCloseCallback,
  getModalLength,
  commonVAProps,
  setLoading,
  closeModal
}) => {
  const key = name
  const modal = modals?.[key]
  const { category, config, isOpen, props, requireAuthLogin, closeCallback } =
    modal || {}
  const { authProvider, environment, dir } = useContextAPI()
  const { t } = useI18n()
  const [checkIsOpen, setCheckIsOpen] = useState(false)
  const { platform } = environment
  const { setAuthToken } = authProvider

  useEffect(() => {
    const checkLogin = async () => {
      if (requireAuthLogin) {
        if (platform !== JARVIS_ENVIRONMENT.WEB) {
          const auth = await setAuthToken()
          if (auth) setCheckIsOpen(true)
        } else {
          setCheckIsOpen(true)
        }
      } else {
        setCheckIsOpen(true)
      }
    }
    checkLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requireAuthLogin, isOpen])

  if (!modal) return null

  return (
    <Modals
      key={key}
      modal={key}
      type={category?.type}
      {...modal}
      modalTitle={modal?.modalTitle ? modal?.modalTitle : t('common.myHPVA')}
      title={getTitle(category?.type, category?.contextTitle)}
      subTitle={category?.contextSubTitle}
      sessionStatus={getSessionStatus(category?.type, key)}
      closeCallback={
        key === VAType.VAFlag
          ? vaCloseCallback
          : closeCallback
          ? closeCallback
          : () => closeModal(key)
      }
      modalLength={getModalLength()}
      checkIsOpen={checkIsOpen}
      dir={dir}
      i18n={{
        minimize: t('common.minimizeLowerCase'),
        maximize: t('common.maximizeLowerCase'),
        close: t('common.closeLowerCase')
      }}
    >
      {key === VAType.VAFlag &&
        isOpen &&
        [VAType.VAASType, VAType.VAFiveGType, VAType.VAMSType].includes(
          category.type
        ) && (
          <SupportHomeVA
            type={category.type}
            config={config}
            {...commonVAProps()}
            {...props}
          />
        )}
      {key === VAType.VAFlag &&
        isOpen &&
        [
          VAType.VADiagnostics,
          VAType.PrinterAndInkSupport,
          VAType.HPAllInPlanSupportPeople
        ].includes(category.type) && (
          <SupportDeviceDetailVA
            type={category?.type}
            config={config}
            {...commonVAProps()}
            {...props}
          />
        )}
      {key != VAType.VAFlag &&
        isOpen &&
        (key == 'externalContent' ? (
          <>
            <ExternalContent
              setLoading={setLoading}
              {...modal.props}
            ></ExternalContent>
          </>
        ) : (
          <Section id={key}>
            {typeof modal.component == JSType.Object ? (
              modal.component
            ) : (
              <DynamicComponent
                component={modal.component}
                props={{
                  ...props,
                  modal: { resize: modal.resize }
                }}
              ></DynamicComponent>
            )}
          </Section>
        ))}
    </Modals>
  )
}

export default ModalLoader
