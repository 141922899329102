import { useJarvisAnalyticsContext } from '@jarvis/jweb-analytics'
import { useEffect, useState } from 'react'
import * as SupportUtil from '../utils'

const useScreen = () => {
  const context = useJarvisAnalyticsContext()
  const [BaseScreenPath, setBaseScreenPath] = useState(
    SupportUtil.BaseScreenPath
  )
  const [DefaultScreenName, setDefaultScreenName] = useState(
    SupportUtil.DefaultScreenName
  )
  const [DeviceScreenName, setDeviceScreenName] = useState(
    SupportUtil.DeviceScreenName
  )
  const [currentScreenName, setCurrentScreenName] = useState<
    string | undefined
  >(SupportUtil.DefaultScreenName)
  useEffect(() => {
    context.updateScreenData({
      screenName: currentScreenName,
      screenPath: `${BaseScreenPath}/${SupportUtil.Support}/`,
      activity: `${currentScreenName}-v01`
    })
  }, [context, currentScreenName])
  const updateScreen = (currentScreenName) => {
    console.log('******pathName******', currentScreenName)
    setCurrentScreenName(currentScreenName)
  }
  const initialData = async () => {
    const { platform, isNative } = await SupportUtil.ensureWeb()
    console.log(`platform:${platform}`, `isNative:${isNative}`)
    console.log(
      `JARVIS_ENVIRONMENT.WEB:${SupportUtil.JARVIS_ENVIRONMENT.WEB}`,
      `SupportUtil.JARVIS_ENVIRONMENT.WINDOWS:${SupportUtil.JARVIS_ENVIRONMENT.WINDOWS}`
    )
    if (
      platform == SupportUtil.JARVIS_ENVIRONMENT.WEB ||
      platform == SupportUtil.JARVIS_ENVIRONMENT.WINDOWS
    ) {
      setBaseScreenPath('/HpxWinClient')
      setDefaultScreenName('SupportHome')
      setDeviceScreenName('DeviceSupport')
      console.log('web platform', platform, isNative)
    } else {
      setBaseScreenPath('/HpxMobileClient')
      setDefaultScreenName('MobileSupportHome')
      setDeviceScreenName('MobileDeviceSupport')
      console.log('mobile platform', platform, isNative)
    }
  }
  useEffect(() => {
    initialData()
  }, [])

  return {
    updateScreen,
    BaseScreenPath,
    DefaultScreenName,
    DeviceScreenName
  }
}

export default useScreen
