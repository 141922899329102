import { useEffect, useState } from 'react'
import {
  getSupportConsent,
  setSupportConsent
} from '@jarvis/web-support-commons/dist/privacy'
import { left } from '../styles/global'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import useContextAPI from './useContextAPI'
import useDialog from '@jarvis/react-hpx-support-shared/dist/hooks/useDialog'
import useModal from '@jarvis/react-hpx-support-shared/dist/hooks/useModal'

const useOptin = () => {
  const [optin, setOptin] = useState<boolean | undefined>()
  const { closeDialog, openDialog } = useDialog()
  const { openModal } = useModal()
  const { dir } = useContextAPI()
  const { t } = useI18n()

  useEffect(() => {
    ;(async () => {
      const optin = await getSupportConsent()
      setOptin(optin)
    })()
  }, [])

  const optinConfirm = ({ okCallback = () => null, device = null }) => {
    openDialog({
      title: t('common.allowPersonalizedSupport'),
      content: t('common.allowPersonalizedSupportDescription'),
      align: left({ theme: { dir } }),
      okText: t('common.yes'),
      okCallback: async () => {
        // const isManagedConsent = await managedConsent()
        // if (!isManagedConsent) {
        await setSupportConsent(true)
        setOptin(await getSupportConsent())
        //}
        okCallback?.()
        closeDialog()
      },
      cancelText: t('common.no'),
      cancelCallback: () => closeDialog(),
      link: {
        text: t('common.viewDataCollected'),
        onClick: () => {
          openViewDataCollected({ device })
        }
      }
    })
  }

  const openViewDataCollected = ({ device }) => {
    const externalContent = {
      isOpen: true,
      modalTitle: '',
      category: {
        type: 'externalContent'
      },
      hide: false,
      resize: false,
      sessionStatus: false,
      isResizable: false,
      width: '80%',
      background: true,
      props: { content: 'viewDataCollected', device }
    }
    openModal({ externalContent })
  }

  return {
    optin,
    setOptin,
    optinConfirm
  }
}

export default useOptin
