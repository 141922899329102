/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, Suspense, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Route, Routes } from 'react-router-dom'
import MicroFrontend from '@jarvis/react-hpx-support-shared/dist/components/MFELoader/MicroFrontend'
import {
  Container,
  Row,
  Section,
  Widget,
  Scrollbar,
  Banner,
  ImageContainer
} from './styles'
import NavigationHeader from '../Dashboard/widget/NavigationHeader'
import CustomImage from '@veneer/core/dist/esm/scripts/custom_image'
import { HPOne } from '../../assets/images'
import {
  WarrantySkelton,
  SupportSkelton,
  DiagnosticSkelton,
  DeviceSkelton,
  CasesSkelton
} from '@jarvis/react-hpx-support-shared/dist/components/Skelton'
import {
  formatAutoId,
  TestIDPrefix,
  ContainerComponent,
  genTestId
} from '@jarvis/react-hpx-support-shared/dist/utils'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import useDialog from '@jarvis/react-hpx-support-shared/dist/hooks/useDialog'
import useModal from '@jarvis/react-hpx-support-shared/dist/hooks/useModal'
import useContextAPI from '../../hooks/useContextAPI'
import useRefresh from '../../hooks/useRefresh'
import useUtils from '../../hooks/useUtils'
import useScreen from '../../hooks/useScreen'
import useOptin from '../../hooks/useOptin'
import { withAnalytics } from '@veneer/analytics'
import { IRoute } from '../../hooks/useRouter'
import { VAType } from '../Modals/types'
import { useSupportSkeleton } from '../../hooks/useInit'

const HomeVA = React.lazy(() => import('../../mobile/VirtualAssistant/HomeVA'))
const DeviceVA = React.lazy(
  () => import('../../mobile/VirtualAssistant/DeviceVA')
)
const VAChat = React.lazy(() => import('../../mobile/VirtualAssistant/VAChat'))
const OminiChat = React.lazy(() => import('../../mobile/OminiChat'))
const TrackContainer = withAnalytics(Container)
const lazyLoad = (component) =>
  // @ts-ignore
  React.lazy(() => System.import(component))

const Dashboard = ({
  stack,
  navigation,
  device,
  devices,
  hostDevice,
  getWarrantyInfo,
  loading,
  updateDeviceListBySN,
  refreshDeviceList,
  getDeviceListWarranty
}) => {
  const { t } = useI18n()
  const { openDialog, closeDialog } = useDialog()
  const {
    sharedProvider,
    methoneProvider,
    authProvider,
    environment,
    featureFlag,
    dir,
    themeProvider,
    routersProvider
  } = useContextAPI()

  const { showRNVAModal } = useSupportSkeleton()

  const { choosePlatformMFEs, getRouteByPlatform } = routersProvider
  const mfe = choosePlatformMFEs()
  const MFE_REFS = useRef({
    SUPPORT: lazyLoad(`@jarvis/${mfe}react-hpx-support`),
    WARRANTY: lazyLoad(`@jarvis/${mfe}react-hpx-warranty`),
    DIAGNOSTICS: lazyLoad(`@jarvis/${mfe}react-hpx-diagnostics`),
    CASES: lazyLoad(`@jarvis/${mfe}react-hpx-support-cases`),
    DASHBOARD: lazyLoad(`@jarvis/${mfe}react-hpx-support-dashboard`),
    CASE_CREATE: lazyLoad(`@jarvis/${mfe}react-hpx-case-create`)
  })

  const route: IRoute = getRouteByPlatform()
  // console.log(`homePath ` + route?.homePath)
  // console.log(`deviceDetailPath ` + route?.deviceDetailPath)
  // console.log(`navigatePath ` + route?.navigatePath)
  // console.log(`supportRoute ` + route?.supportRoute)

  const refreshProvider = useRefresh()
  const { state, showBack, isSmallScreen, onBack, refScroll } = useUtils({
    hostDevice
  })
  const { updateScreen, DefaultScreenName, DeviceScreenName } = useScreen()
  const optinProvider = useOptin()
  const { MethoneClient, MethoneWinClient } = methoneProvider
  const { isSignedIn, profile } = authProvider
  const { refreshList } = refreshProvider
  const { setLoading } = useModal()
  const { showBanner } = sharedProvider?.data || {}
  const navState = navigation?.location?.state as any

  const CreateCaseWidget = useCallback(
    ({ type }) => {
      return (
        <>
          <MicroFrontend
            type={type}
            card={true}
            component={MFE_REFS.current?.CASE_CREATE}
            data-testid="hpx-support-agent-create-mfe"
            header={false}
            refreshProvider={refreshProvider}
            authProvider={authProvider}
            themeProvider={themeProvider}
            device={device}
            setLoading={setLoading}
            MethoneClient={MethoneClient}
            formatAutoId={formatAutoId}
            environment={environment}
            Skelton={CasesSkelton}
            modal={null}
            navigation={navigation}
            navigatePath={route?.navigatePath}
          />
        </>
      )
    },
    [device, themeProvider?.mode]
  )
  const DashboardWidget = useMemo(
    () => (
      <>
        {
          <MicroFrontend
            card={true}
            component={MFE_REFS.current?.DASHBOARD}
            header={false}
            devices={devices}
            device={hostDevice}
            navigation={navigation}
            useModal={useModal}
            openDialog={openDialog}
            closeDialog={closeDialog}
            formatAutoId={formatAutoId}
            environment={environment}
            updateScreen={updateScreen}
            defaultScreenName={DefaultScreenName}
            Skelton={DeviceSkelton}
            loader={true}
            loading={loading}
            refreshDeviceList={refreshDeviceList}
            featureFlag={featureFlag}
            optinProvider={optinProvider}
            getDeviceListWarranty={getDeviceListWarranty}
            authProvider={authProvider}
            themeProvider={themeProvider}
            navigatePath={route?.navigatePath}
            supportRoute={route?.supportRoute}
          />
        }
      </>
    ),
    [
      devices,
      hostDevice,
      loading,
      featureFlag?.deviceListEnabled,
      optinProvider?.optin,
      isSignedIn,
      themeProvider?.mode
    ]
  )

  const SupportResourceWidget = useMemo(() => {
    return (
      <>
        <MicroFrontend
          key="SupportResourceWidget"
          card={true}
          component={MFE_REFS.current?.SUPPORT}
          header={false}
          device={device}
          MethoneClient={MethoneClient}
          MethoneWinClient={MethoneWinClient}
          useModal={useModal}
          openDialog={openDialog}
          closeDialog={closeDialog}
          formatAutoId={formatAutoId}
          sharedProvider={sharedProvider}
          authProvider={authProvider}
          refreshProvider={refreshProvider}
          stack={stack}
          environment={environment}
          updateDeviceListBySN={updateDeviceListBySN}
          Skelton={SupportSkelton}
          getWarrantyInfo={getWarrantyInfo}
          optinProvider={optinProvider}
          featureFlag={featureFlag}
          themeProvider={themeProvider}
          loader={true}
          navigatePath={route?.navigatePath}
        />
      </>
    )
  }, [
    device?.SerialNumber,
    device?.DeviceId,
    device?.WarrantyState,
    device?.SupportCode,
    device?.warrantyInfo,
    device?.subscriptionInfo,
    device?.OIDInfoReady,
    featureFlag?.deviceListEnabled,
    isSignedIn,
    profile,
    optinProvider?.optin,
    themeProvider?.mode
  ])

  const SupportResourceWidgetMobile = useCallback(
    ({ type }) => {
      return (
        <>
          <MicroFrontend
            type={type}
            key="SupportResourceWidget"
            card={true}
            component={MFE_REFS.current?.SUPPORT}
            header={false}
            device={device}
            MethoneClient={MethoneClient}
            MethoneWinClient={MethoneWinClient}
            useModal={useModal}
            openDialog={openDialog}
            closeDialog={closeDialog}
            formatAutoId={formatAutoId}
            sharedProvider={sharedProvider}
            authProvider={authProvider}
            refreshProvider={refreshProvider}
            stack={stack}
            environment={environment}
            updateDeviceListBySN={updateDeviceListBySN}
            Skelton={SupportSkelton}
            getWarrantyInfo={getWarrantyInfo}
            optinProvider={optinProvider}
            featureFlag={featureFlag}
            themeProvider={themeProvider}
            loader={true}
            navigatePath={route?.navigatePath}
          />
        </>
      )
    },
    [
      device?.SerialNumber,
      device?.DeviceId,
      device?.WarrantyState,
      device?.SupportCode,
      device?.warrantyInfo,
      device?.subscriptionInfo,
      featureFlag?.deviceListEnabled,
      isSignedIn,
      profile,
      optinProvider?.optin,
      themeProvider?.mode
    ]
  )

  const WarrantyWidget = useMemo(
    () => (
      <>
        <MicroFrontend
          component={MFE_REFS.current?.WARRANTY}
          card={true}
          device={device}
          useModal={useModal}
          openDialog={openDialog}
          closeDialog={closeDialog}
          formatAutoId={formatAutoId}
          authProvider={authProvider}
          environment={environment}
          updateScreen={updateScreen}
          deviceScreenName={DeviceScreenName}
          getWarrantyInfo={getWarrantyInfo}
          Skelton={WarrantySkelton}
          optinProvider={optinProvider}
          MethoneWinClient={MethoneWinClient}
          updateDeviceListBySN={updateDeviceListBySN}
          themeProvider={themeProvider}
          loader={true}
        />
      </>
    ),
    [
      device?.SerialNumber,
      device?.DeviceId,
      device?.ImageLink,
      device?.subscriptionInfo,
      device?.warrantyInfo,
      device?.NickName,
      device?.loading,
      optinProvider?.optin,
      isSignedIn,
      themeProvider?.mode
    ]
  )

  const DiagnosticsWidget = useMemo(() => {
    return (
      <>
        <Row>
          <MicroFrontend
            card={true}
            component={MFE_REFS.current?.DIAGNOSTICS}
            header={false}
            device={device}
            MethoneClient={MethoneClient}
            openDialog={openDialog}
            closeDialog={closeDialog}
            formatAutoId={formatAutoId}
            useModal={useModal}
            environment={environment}
            Skelton={DiagnosticSkelton}
            themeProvider={themeProvider}
            navigation={navigation}
            supportRoute={route.supportRoute}
            showRNVAModal={showRNVAModal}
          />
        </Row>
      </>
    )
  }, [device?.SerialNumber, device?.ImageLink, themeProvider?.mode])

  const CasesWidget = useMemo(() => {
    return (
      <>
        {device?.SerialNumber &&
          device?.ProductNumber &&
          device?.DeviceId != undefined &&
          device?.DeviceId != 0 &&
          isSignedIn && (
            <Row
              className={
                device?.ProductType === 1 && device?.IsSubscriptionDevice
                  ? 'm-b-24 m-t-40'
                  : 'm-b-24'
              }
            >
              <MicroFrontend
                card={true}
                component={MFE_REFS.current?.CASES}
                header={false}
                device={device}
                MethoneClient={MethoneClient}
                openDialog={openDialog}
                closeDialog={closeDialog}
                formatAutoId={formatAutoId}
                refresh={refreshList?.cases}
                environment={environment}
                authProvider={authProvider}
                Skelton={CasesSkelton}
                themeProvider={themeProvider}
                navigation={navigation}
                navigatePath={route?.navigatePath}
              />
            </Row>
          )}
      </>
    )
  }, [device, isSignedIn, refreshList?.cases, themeProvider?.mode])

  const CasesWidgetMobile = useCallback(
    ({ type }) => {
      return (
        <>
          {device?.SerialNumber &&
            device?.ProductNumber &&
            device?.DeviceId != undefined &&
            device?.DeviceId != 0 &&
            isSignedIn && (
              <Row className="m-b-24">
                <MicroFrontend
                  type={type}
                  card={true}
                  component={MFE_REFS.current?.CASES}
                  header={false}
                  device={device}
                  MethoneClient={MethoneClient}
                  openDialog={openDialog}
                  closeDialog={closeDialog}
                  formatAutoId={formatAutoId}
                  refresh={refreshList?.cases}
                  environment={environment}
                  authProvider={authProvider}
                  Skelton={CasesSkelton}
                  themeProvider={themeProvider}
                  navigation={navigation}
                  navigatePath={route?.navigatePath}
                />
              </Row>
            )}
        </>
      )
    },
    [device, isSignedIn, refreshList?.cases, themeProvider?.mode]
  )

  const getWarrantyComponent = () => (
    <>
      {device?.IsSubscriptionDevice &&
        device?.IsSubscriptionActive &&
        environment.isMobile &&
        showBanner && (
          <Banner>
            {environment.isMobile && (
              <ImageContainer>
                <CustomImage viewBox="0 0 60 28" className="hpone" role="img">
                  <HPOne />
                </CustomImage>
              </ImageContainer>
            )}
          </Banner>
        )}

      <Widget>{WarrantyWidget}</Widget>
    </>
  )

  return (
    <TrackContainer trackId={genTestId(TestIDPrefix, ContainerComponent)}>
      <NavigationHeader
        showBack={showBack}
        onBack={onBack}
        t={t}
        navigation={navigation}
      />
      <Routes>
        <Route
          path={route?.homePath}
          element={
            <Scrollbar
              direction={dir}
              data-simplebar-direction={dir}
              ref={refScroll}
              autoHide={false}
              style={{ flex: 1, height: '100%', overflow: 'auto' }}
            >
              <Section>{DashboardWidget}</Section>
            </Scrollbar>
          }
        />
        <Route
          path={route?.deviceDetailPath}
          element={
            <>
              {!environment.isMobile &&
                !isSmallScreen &&
                getWarrantyComponent()}
              <Scrollbar
                direction={dir}
                data-simplebar-direction={dir}
                ref={refScroll}
                autoHide={false}
                style={{ flex: 1, height: '100%', overflow: 'auto' }}
              >
                {(environment.isMobile || isSmallScreen) &&
                  getWarrantyComponent()}
                <Section className="p-b-28">
                  {device?.ProductType === 1 && device?.IsSubscriptionDevice ? (
                    <>
                      {SupportResourceWidget}
                      {DiagnosticsWidget}
                      {CasesWidget}
                    </>
                  ) : (
                    <>
                      {SupportResourceWidget}
                      {CasesWidget}
                      {DiagnosticsWidget}
                    </>
                  )}
                </Section>
              </Scrollbar>
            </>
          }
        />
        {/* TODO: for fallback, will handle mobile skeleton page in future */}
        <Route
          path={route?.homePath + 'guidedtroubleshooting'}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Scrollbar
                direction={dir}
                data-simplebar-direction={dir}
                ref={refScroll}
                autoHide={false}
                style={{ flex: 1, height: '100%', overflow: 'auto' }}
              >
                {navState?.type == VAType.VADiagnostics ? (
                  <DeviceVA
                    type={navState?.type}
                    device={device}
                    config={navState?.config}
                  />
                ) : (
                  <HomeVA
                    type={navState?.type}
                    device={device}
                    config={navState?.config}
                  />
                )}
              </Scrollbar>
            </Suspense>
          }
        />
        <Route
          path={route?.homePath + 'virtualassistant'}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <VAChat vaUrl={state?.vaUrl} />
            </Suspense>
          }
        />
        <Route
          path={route?.deviceDetailPath + '/contactus/chat/ominichat'}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <OminiChat />
            </Suspense>
          }
        />
        <Route
          path={route?.deviceDetailPath + '/contactus'}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Scrollbar
                direction={dir}
                data-simplebar-direction={dir}
                ref={refScroll}
                autoHide={false}
                style={{ flex: 1, height: '100%', overflow: 'auto' }}
              >
                {<SupportResourceWidgetMobile type="contactUs" />}
              </Scrollbar>
            </Suspense>
          }
        />

        <Route
          path={route?.deviceDetailPath + '/contactus/chat'}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Scrollbar
                direction={dir}
                data-simplebar-direction={dir}
                ref={refScroll}
                autoHide={false}
                style={{ flex: 1, height: '100%', overflow: 'auto' }}
              >
                <CasesWidgetMobile type={'agent'} />
              </Scrollbar>
            </Suspense>
          }
        />
        <Route
          path={route?.deviceDetailPath + '/contactus/speak'}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Scrollbar
                direction={dir}
                data-simplebar-direction={dir}
                ref={refScroll}
                autoHide={false}
                style={{ flex: 1, height: '100%', overflow: 'auto' }}
              >
                <CreateCaseWidget type={'speaktoAgent'} />
              </Scrollbar>
            </Suspense>
          }
        />
        <Route
          path={route?.deviceDetailPath + '/case/casedetails'}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Scrollbar
                direction={dir}
                data-simplebar-direction={dir}
                ref={refScroll}
                autoHide={false}
                style={{ flex: 1, height: '100%', overflow: 'auto' }}
              >
                <CasesWidgetMobile type={'caseDetail'} />
              </Scrollbar>
            </Suspense>
          }
        />
      </Routes>
    </TrackContainer>
  )
}

Dashboard.defaultProps = {
  stack: 1,
  properties: {}
}

Dashboard.propTypes = {
  stack: PropTypes.number,
  properties: PropTypes.objectOf(PropTypes.any)
}

export default React.memo(Dashboard)
