import { createGlobalStyle, css } from 'styled-components'
import '@veneer/core/dist/css/veneer.css'
import '@veneer/core/dist/css/shared/animated_height.css'

export default createGlobalStyle`
  ${({ theme }) => {
    return css`
      html,
      body {
        overflow: hidden;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      body {
        b,
        strong {
          font-family: ${theme.fontTextMedium};
        }
        h4,
        .title-small,
        h6,
        .subtitle-regular,
        h5,
        .subtitle-large,
        label,
        .label,
        .body,
        .caption-small {
          font-family: ${theme.fontTextRegular};
        }
        .regular {
          font-family: ${theme.fontTextRegular};
        }
        .medium {
          font-family: ${theme.fontTextMedium};
        }
      }
      html,
      body {
        height: 100% !important;
        background: ${theme.colorBackground} !important;
        font-family: ${theme.fontTextRegular} !important;
      }
      a,
      .link {
        color: ${theme.colorPrimary} !important;
        &:hover {
          color: ${theme.colorPrimaryHover} !important;
        }
        &:active {
          color: ${theme.colorPrimaryActive} !important;
        }
        &:focus {
          outline: none !important;
        }
        &:focus[data-focus='true'] {
          position: relative;
          &:after {
            top: -8px;
            left: -8px;
            right: -8px;
            bottom: -8px;
            position: absolute;
            border: 2px solid ${theme.colorPrimary};
            border-radius: 4px;
            content: ' ';
          }
        }
      }
      .cardStyle {
        &:focus {
          outline: none !important;
        }
        &:focus[data-focus='true'] {
          position: relative;
          overflow: unset;
          &:after {
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
            position: absolute;
            border: 2px solid ${theme.colorPrimary};
            border-radius: 8px;
            content: ' ';
          }
        }
      }
      .simplebar-scrollbar {
        &:before {
          -ms-high-contrast-adjust: none;
          background: ${theme.colorForeground} !important;
        }
      }
      .simplebar-track.simplebar-horizontal {
        height: 6px !important;
      }
      input::-ms-clear {
        display: none;
      }
    `
  }}

  @media screen and (-ms-high-contrast: white-on-black) {
    img.contrast {
      filter: invert(1);
    }
    img.invert {
      filter: contrast(1);
    }
  }
  @media screen and (-ms-high-contrast: active) {
    ${({ theme }) =>
      theme.mode == 'light' &&
      css`
        div[data-type='dialog'] {
          background-color: transparent;
        }
        .simplebar-scrollbar {
          &:before {
            background: WindowText !important;
          }
        }
        button.link {
          > * {
            -ms-high-contrast-adjust: none;
            background-color: transparent !important;
            color: -ms-hotlight !important;
          }
          &: hover {
            > * {
              -ms-high-contrast-adjust: none;
              color: WindowText !important;
            }
          }
        }
        button.ghost {
          transition: unset !important;
          &: hover {
            -ms-high-contrast-adjust: none;
            border-color: highlight !important;
            svg,
            span {
              -ms-high-contrast-adjust: none;
              background-color: transparent !important;
              color: highlight !important;
            }
          }
        }
        button.secondary:not(.ghost) {
          transition: unset !important;
          color: ButtonText !important;
          border-color: buttontext;
          &:hover {
            border-color: Highlight !important;
            > * {
              -ms-high-contrast-adjust: none;
              background-color: transparent !important;
              color: Highlight !important;
            }
          }
        }
        button:not(disabled):not(.secondary):not(.ghost):not(.link) {
          transition: unset !important;
          > * {
            -ms-high-contrast-adjust: none;
            background-color: Highlight !important;
            color: HighlightText !important;
          }
          background-color: Highlight !important;
          color: HighlightText !important;
          &:hover {
            background-color: buttontext !important;
            color: HighlightText !important;
            > * {
              background-color: buttontext !important;
              color: window !important;
              -ms-high-contrast-adjust: none;
            }
          }
        }
        button:disabled:not(.secondary):not(.ghost):not(.link) {
          > * {
            -ms-high-contrast-adjust: none;
            background-color: GrayText !important;
          }
          background-color: GrayText !important;
        }
        .vn-option {
          transition: unset !important;
          &:hover,
          &:not(.disabled)[data-focus='true'] {
            background-color: highlight !important;
            > * {
              -ms-high-contrast-adjust: none;
              background-color: highlight !important;
              color: highlightText;
            }
          }
        }
        .checkBox.checked {
          > span:first-child {
            background-color: highlight !important;
          }
        }
      `}
    img.contrast {
      filter: contrast(1);
    }
    img.invert {
      filter: invert(1);
    }
  }
`

const breakpoints = {
  L: '1008px',
  M: '640px',
  S: '500px'
}

const rtl =
  (left, right) =>
  ({ theme }) =>
    theme.dir == 'rtl' ? right : left
export const left = rtl('left', 'right')
export const right = rtl('right', 'left')

const sizeL = parseInt(breakpoints.L.replace('px', ''))
const sizeM = parseInt(breakpoints.M.replace('px', ''))
const maxM = sizeL - 1
const minM = sizeM + 1

export const HPXTheme = {
  breakpoints,
  mediaQueries: {
    L: `@media only screen and (min-width: ${breakpoints.L})`,
    M: `@media only screen and (max-width: ${maxM}px) and (min-width: ${minM}px)`,
    S: `@media only screen and (max-width: ${breakpoints.M})`,
    XS: `@media only screen and (max-width: ${breakpoints.S})`
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0])
  return this
}
