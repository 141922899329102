import { Stack } from '../types/stratus'
import { get } from '@jarvis/web-support-commons/dist/asyncStorage'

const METHONE = {
  ITG: {
    API_URL: 'https://api3-itg-methone.hpcloud.hp.com/stg-v4/',
    BOT_PATH: 'svl-stg'
  },
  PROD: {
    API_URL: 'https://api3-methone.hpcloud.hp.com/v4/',
    BOT_PATH: 'svl'
  }
}

// const BOT_API_URL = 'https://api2-itg-methone.hpcloud.hp.com'
const VA__URL_DEV = 'https://virtualagent-stg.hpcloud.hp.com'
const VA__URL_PROD = 'https://virtualagent.hpcloud.hp.com'

export enum CLIENT_IDS {
  HPX = 'HPX'
}

export const getEnv = (stack: Stack) => stack || Stack.prod

let Config = null
const getConfig = async () => {
  if (!Config) {
    const key = (await get('@env/METHONE_APIKEY')) ?? ''
    const url = (await get('@env/METHONE_APIURL')) ?? ''
    // VA 's URL is defined base on HPX portal env in HPX 0.5(Only pro portal using PRO VA URL),we can change VA to align with the Methone's env later
    let vaSubDomain = ''
    // Change the VA URL from stg to pro.
    let vaUrl = ''
    if (url) {
      if (url.includes('itg')) {
        vaSubDomain = METHONE.ITG.BOT_PATH
        vaUrl = VA__URL_DEV
      } else {
        vaSubDomain = METHONE.PROD.BOT_PATH
        vaUrl = VA__URL_PROD
      }
    }
    Config = {
      API_KEY: key || process.env.HPX_METHONE_APIKEY_V4,
      [Stack.dev]: {
        API_URL: url || METHONE.ITG.API_URL,
        BOT_PATH: vaSubDomain || METHONE.ITG.BOT_PATH,
        VA__URL: vaUrl || VA__URL_DEV
      },
      [Stack.pie]: {
        API_URL: url || METHONE.ITG.API_URL,
        BOT_PATH: vaSubDomain || METHONE.ITG.BOT_PATH,
        VA__URL: vaUrl || VA__URL_DEV
      },
      [Stack.stage]: {
        API_URL: url || METHONE.ITG.API_URL,
        BOT_PATH: vaSubDomain || METHONE.ITG.BOT_PATH,
        VA__URL: vaUrl || VA__URL_DEV
      },
      [Stack.prod]: {
        API_URL: url || METHONE.PROD.API_URL,
        BOT_PATH: vaSubDomain || METHONE.PROD.BOT_PATH,
        VA__URL: vaUrl || VA__URL_PROD
      }
    }
  }
  return Config
}

export default getConfig
