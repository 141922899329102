/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import {
  MethoneAPIClient,
  MethoneConfig
} from '@jarvis/web-support-commons/dist/index'
import getConfig, { getEnv, CLIENT_IDS } from './config'

export const useMethoneClient = ({ stack, auth }) => {
  const [MethoneClient, setMethoneClient] = useState(null)
  const [config, setConfig] = useState(null)

  useEffect(() => {
    if (auth && MethoneClient) MethoneClient.auth = auth
  }, [auth, MethoneClient])

  useEffect(() => {
    const getMethoneClient = async () => {
      const Config = await getConfig()
      const ENV = getEnv(stack)
      const _config: MethoneConfig = {
        apiKey: Config.API_KEY,
        basePath: Config[ENV]?.API_URL,
        botEndpoint: Config[ENV].BOT_PATH,
        clientId: CLIENT_IDS.HPX || ''
      }
      setConfig({
        ..._config,
        VA_URL: Config[ENV]?.VA__URL
      })
      const MethoneConfig = {
        ..._config
      }
      const MethoneClient = new MethoneAPIClient(MethoneConfig)
      if (auth) MethoneClient.auth = auth
      setMethoneClient(MethoneClient)
    }
    if (!MethoneClient) getMethoneClient()
  }, [stack])

  return {
    MethoneClient,
    MethoneConfig: config
  }
}
