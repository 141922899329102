import styled, { css } from 'styled-components'
import Tokens from '@veneer/tokens'
import { left, right } from '../../styles/global'
import Button from '@veneer/core/dist/esm/scripts/button/button'
import Scrollbars from '@jarvis/react-hpx-support-shared/dist/components/Scrollbar'
const colorIndigoSlate = '#363645'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${({ theme }) => theme?.colorBackground || Tokens.colorGray0};
  height: 100vh;
`

export const Widget = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme?.colorNeutralBackgroundLight};
  padding: 0px calc(${Tokens.space8} + ${Tokens.space2});
  ${({ theme }) =>
    theme?.isMobile &&
    css`
      padding: 0px 0px;
    `}
`

export const Section = styled.div`
  padding: calc(${Tokens.space8} + ${Tokens.space2})
    calc(${Tokens.space8} + ${Tokens.space2});
  ${({ theme }) =>
    theme?.isMobile &&
    css`
      padding: 0px calc(${Tokens.space4});
      margin-top: ${Tokens.space5};
    `}
  &.p-b-28 {
    padding-bottom: ${Tokens.space7};
  }
  z-index: 1;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  &.m-b-24 {
    margin-bottom: ${Tokens.space6};
  }
  &.m-t-40 {
    margin-top: calc(${Tokens.space8} + ${Tokens.space2});
  }
`

export const Header = styled.header`
  background: ${({ theme }) =>
    theme?.isMobile
      ? theme?.colorBackground || Tokens.colorGray0
      : colorIndigoSlate};
  height: 70px;
  min-height: 70px;
  display: flex;
  padding: 0px calc(${Tokens.space8} + ${Tokens.space2});
  ${({ theme }) =>
    theme?.isMobile &&
    css`
      padding: 0px;
    `}
  > div {
    align-items: center;
    ${({ theme }) =>
      theme?.isMobile &&
      css`
        width: 100%;
      `}
    h4 {
      margin-${left}: ${Tokens.space2};
      color: ${({ theme }) =>
        theme.mode === 'dark'
          ? Tokens.colorWhite
          : theme.colorForegroundInverse};
      ${({ theme }) =>
        theme?.isMobile &&
        css`
          color: ${theme?.colorForeground || Tokens.colorGray10};
          width: 100%;
          text-align: center;
          margin-left: calc(-28px);
        `}
    }
  }
  .support {
     path {
        stroke: currentColor;
        stroke-width: 0.5;
        stroke-linecap: round;
     }
  }
  @media only screen and (max-width: ${Tokens.xsMax}) {
    height: 53px;
    min-height: 53px;
    .support {
      width: 28px;
    }
  }
  @media only screen and (min-width: ${Tokens.smMin}) {
    height: 60px;
    min-height: 60px;
    .support {
      width: 32px;
    }
  }
  @media only screen and (min-width: ${Tokens.lgMin}) {
    height: 70px;
    min-height: 70px;
    .support {
      width: 40px;
    }
  }
`

// TODO; linear-gradient should be change to Veneer' styles?
export const LinearGradientHeader = styled.div`
  height: ${Tokens.space4};
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.14) 0%,
    rgba(0, 0, 0, 0.05) 35.94%,
    rgba(0, 0, 0, 0.0126016) 60.94%,
    rgba(0, 0, 0, 0) 100%
  );
`

export const Loader = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
`

export const BackButton = styled(Button)`
  &.backButton {
    border: ${({ theme }) =>
      theme?.isMobile ? '0px' : `1px solid ${theme.colorWhite}`};
    margin-${right}: ${Tokens.space4} !important;
    svg {
      color: ${({ theme }) =>
        theme?.isMobile
          ? theme?.colorForeground || Tokens.colorGray10
          : theme.colorWhite};
    }
  }
  @media only screen and (max-width: ${Tokens.xsMax}) {
    width: 28px;
    height: 28px;
  }
  @media only screen and (min-width: ${Tokens.smMin}) {
    width: 32px;
    height: 32px;
  }
  @media only screen and (min-width: ${Tokens.lgMin}) {
    width: unset;
    height: unset;
  }
  ${({ theme }) =>
    theme.dir == 'rtl' &&
    css`
      transform: rotate(180deg);
    `}
`
export const Scrollbar = styled(Scrollbars)`
  .simplebar-track.simplebar-vertical {
    width: 6px;
  }
`
export const Banner = styled.div`
  background: rgba(148, 217, 243, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 28px;
  text-align: center;
  padding: 8px 5%;
  ${({ theme }) =>
    theme?.isMobile &&
    css`
      flex-direction: column;
    `}
`
export const ImageContainer = styled.div`
  margin: ${Tokens.space1};
  display: flex;
  align-items: center;
  .hpone {
    width: 60px;
    height: 20px;
    color: ${({ theme }) => theme.colorForeground};
  }
`
export const FloatView = styled.div`
  position: fixed;
  display: grid;
  flex-direction: row-reverse;
  bottom: 0px;
  grid-auto-flow: column;
  margin: 0 40px;
  grid-template-columns: repeat(
    ${(props) => props.size || 2},
    minmax(100px, 800px)
  );
  gap: 16px;
  ${right}: 0px;
  width: calc(100% - 80px);
  justify-content: center;
`
