import { useState, useEffect, useCallback } from 'react'
const useWinSize = () => {
  const [size, setSize] = useState({
    height: document.documentElement.clientHeight,
    width: document.documentElement.clientWidth
  })
  const handleResize = useCallback(() => {
    setSize({
      height: document.documentElement.clientHeight,
      width: document.documentElement.clientWidth
    })
  }, [])
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return size
}

export default useWinSize
