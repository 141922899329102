import { useEffect, useState } from 'react'
import { isFeatureEnabled } from '@jarvis/web-support-commons/dist/feature-switch'
import { JARVIS_ENVIRONMENT } from '../utils'
import IFeatureFlag from '../types/featureFlag'

const initialState: IFeatureFlag = {
  deviceListEnabled: false,
  isSupportHomeEnabled: false
}

const useFeatureFlag = ({ environment }) => {
  const { platform } = environment || {}
  const [featureFlag, setFeatureFlag] = useState<IFeatureFlag>(initialState)

  useEffect(() => {
    ;(async () => {
      if (!platform) return
      const featureFlags = await getFlagList(platform)
      setFeatureFlag((prevState) => ({
        ...prevState,
        ...featureFlags
      }))
    })()
  }, [platform])

  async function getFlagList(platform) {
    switch (platform) {
      case JARVIS_ENVIRONMENT.WEB:
      case JARVIS_ENVIRONMENT.ANDROID:
      case JARVIS_ENVIRONMENT.IOS:
        return { deviceListEnabled: true, isSupportHomeEnabled: false }
      case JARVIS_ENVIRONMENT.WINDOWS: {
        const isSupportHomeEnabled = await isFeatureEnabled('supporthome-core')
        return {
          deviceListEnabled: true,
          isSupportHomeEnabled
        }
      }
      default: {
        return {
          deviceListEnabled: false,
          isSupportHomeEnabled: false
        }
      }
    }
  }

  return featureFlag
}

export default useFeatureFlag
