import React, { useRef, memo, useMemo, useEffect } from 'react'
import { Section, IFrame } from './styles'
import { formatAutoId } from '../../../utils'
import useWinSize from '../../../hooks/useWinSize'
import useContextAPI from '../../../hooks/useContextAPI'
import useModal from '@jarvis/react-hpx-support-shared/dist/hooks/useModal'
interface VaType {
  vaUrl?: string
  type?: string
}

const commonProperties = () => {
  return {
    width: '100%',
    frameBorder: 'no',
    marginWidth: 0,
    marginHeight: 0,
    scrolling: 'no'
  }
}

const focusEvent = () => {
  const firstElement: HTMLElement = document.querySelector(
    '#AUID_Support_modal_minimizeButton'
  )
  firstElement?.focus()
}

// eslint-disable-next-line react/display-name
const VAChat = memo((props: VaType) => {
  const { environment } = useContextAPI()
  const { isMobile } = environment || {}
  console.log(props?.vaUrl)
  const iframeRef = useRef(null)
  const size = useWinSize()
  const { updateVALoad } = useModal()
  const memoUrl = useMemo(() => {
    return props?.vaUrl
  }, [props?.vaUrl])

  useEffect(() => {
    // TODO: platform is not mobile
    if (!isMobile) {
      updateVALoad('VA', props?.type, true)
    }
    setTimeout(() => {
      if (!isMobile) updateVALoad('VA', props?.type, false)
    }, 4500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.type])

  return (
    <Section>
      {memoUrl !== '' && (
        <>
          <IFrame
            // sandbox="allow-same-origin allow-scripts"
            ref={iframeRef}
            src={memoUrl}
            id={formatAutoId('visualAgentUrl_Link')}
            {...commonProperties()}
            height={size.height}
          />
          <span tabIndex={0} onFocus={focusEvent}></span>
        </>
      )}
    </Section>
  )
})

export { VAChat }

export default React.memo(VAChat)
