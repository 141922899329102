/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
import { FaultItemList } from '@jarvis/web-support-commons/dist/methone'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useContextAPI from './useContextAPI'
import useDialog from '@jarvis/react-hpx-support-shared/dist/hooks/useDialog'
import useModal from '@jarvis/react-hpx-support-shared/dist/hooks/useModal'
import { Scrollbar } from '../components/Dashboard/styles'
import { useSupportSkeleton } from '../hooks/useInit'
import useVA from './useVA'
import { JARVIS_ENVIRONMENT } from '../utils'
import { IRoute } from '../hooks/useRouter'

const mediaQuery = window.matchMedia('(max-height: 400px)')

const useUtils = ({ hostDevice }) => {
  const refScroll = useRef<typeof Scrollbar>()
  const [showBack, setShowBack] = useState<boolean>(false)
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false)
  const { confirmDialog } = useVA()
  const { openDialog, closeDialog } = useDialog()
  const navigate = useNavigate()
  const {
    methoneProvider,
    authProvider,
    environment,
    navigation,
    sharedProvider,
    routersProvider,
    featureFlag
  } = useContextAPI()
  const { state, pathname } = useLocation()
  const { MethoneClient } = methoneProvider
  const { setAuthToken, logout, abortAuthCall, isSignedIn } = authProvider
  const { setData } = sharedProvider
  const { modals, closeAllModal, closeModal } = useModal()
  const { hideSkelton } = useSupportSkeleton()
  const { getRouteByPlatform } = routersProvider
  const route: IRoute = getRouteByPlatform()
  const { platform } = environment || {}
  const didMount = useRef(false)
  const { isSupportHomeEnabled } = featureFlag

  // show HPID on load if device is subscription
  useEffect(() => {
    ;(async () => {
      if (!hostDevice || isSupportHomeEnabled) return
      const showHPID =
        hostDevice?.IsSubscriptionDevice &&
        !isSignedIn &&
        platform === JARVIS_ENVIRONMENT.WINDOWS &&
        didMount.current === false
      if (showHPID) {
        await setAuthToken()
      }
      didMount.current = true
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostDevice?.IsSubscriptionDevice, isSignedIn, isSupportHomeEnabled])

  useEffect(() => {
    // @ts-ignore
    System.import('@jarvis/react-hpx-support-dashboard')
    // @ts-ignore
    System.import('@jarvis/react-hpx-support')
    // @ts-ignore
    System.import('@jarvis/react-hpx-warranty')
    // @ts-ignore
    System.import('@jarvis/react-hpx-diagnostics')
    // @ts-ignore
    System.import('@jarvis/react-hpx-support-cases')
    // @ts-ignore
    System.import('@jarvis/react-hpx-case-create')
  }, [])

  useEffect(() => {
    if (hostDevice) hideSkelton('dashboard', 0, false)
  }, [hostDevice])

  useEffect(() => {
    recalculate()
  })

  useEffect(() => {
    setShowBack(
      isSupportHomeEnabled === false && pathname?.includes('support/device')
    )
    abortAuthCall?.()
    setData({ showBanner: false })
  }, [pathname, isSupportHomeEnabled])

  useEffect(() => {
    if (MethoneClient) setErrorCallback()
  }, [MethoneClient])

  useEffect(() => {
    if (!hostDevice) return
    const unblock = navigation.block((e: any): any => {
      if (e?.state?.skipBlock === true) return true
      const callback = () => {
        unblock()
        navigation.push(e.pathname, e.state)
      }
      if (!confirmRoute(callback)) {
        return false
      }
      return true
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, hostDevice, modals])

  useLayoutEffect(() => {
    const handler = (e) => {
      if (e.matches) {
        setIsSmallScreen(true)
      } else {
        setIsSmallScreen(false)
      }
    }
    mediaQuery?.addEventListener
      ? mediaQuery?.addEventListener('change', handler)
      : mediaQuery?.addListener(handler)
    handler(mediaQuery)

    return () => {
      mediaQuery?.removeEventListener
        ? mediaQuery?.removeEventListener('change', handler)
        : mediaQuery.removeListener(handler)
    }
  }, [])

  const recalculate = () => {
    refScroll?.current?.contentWrapperEl?.setAttribute('tabindex', '-1')
    if (refScroll?.current) refScroll.current.recalculate()
  }

  const onBackPath = () => {
    let path = '/'
    switch (platform) {
      case JARVIS_ENVIRONMENT.ANDROID:
        path = path + JARVIS_ENVIRONMENT.ANDROID
        break
      case JARVIS_ENVIRONMENT.IOS:
        path = path + JARVIS_ENVIRONMENT.IOS
        break
      default:
        break
    }
    return path
  }

  const login = async () => {
    try {
      const auth = await setAuthToken()
      if (
        !state?.device.IsHostPC &&
        // pathname?.indexOf('support/device') >= 0 &&
        pathname?.indexOf(route?.navigatePath) >= 0 &&
        !auth
      ) {
        navigation?.push(onBackPath())
      }
    } catch (e) {
      console.log(e)
    }
  }

  const setErrorCallback = () => {
    MethoneClient.onErrorCallback = async (faultItemList: FaultItemList[]) => {
      if (faultItemList && faultItemList[0]) {
        switch (faultItemList[0].ReturnCode) {
          case 'InvalidCredentials':
          case 'SessionTimeout': {
            if (platform === JARVIS_ENVIRONMENT.WINDOWS) {
              await logout()
              await login()
            }
            break
          }
          case 'ServiceOrderNotFound':
          case 'DeviceNotFound':
          case 'DeviceAlreadyRegistered':
            break
          default:
            openDialog({
              title: 'Alert',
              content: faultItemList[0].StatusText,
              okCallback: closeDialog,
              okText: 'OK'
            })
        }
      }
    }
  }

  const confirmRoute = (unblock) => {
    for (const key in modals) {
      if (modals[key]?.resize && !modals[key]?.sessionStatus) {
        closeModal(key)
        closeAllModal()
        unblock()
        return false
      }
      if (modals[key]?.sessionStatus) {
        confirmDialog({
          callbackYes: () => {
            closeDialog()
          },
          callbackNo: () => {
            closeDialog()
            closeModal(key)
            closeAllModal()
            unblock()
          }
        })
        return false
      }
    }
    return true
  }

  function onBack() {
    // const path = onBackPath()
    // // navigation.push(path ? path : '/support')
    // navigation.push(path ? path : route?.supportRoute)
    navigate(-1)
  }

  return {
    state,
    showBack,
    isSmallScreen,
    onBack,
    refScroll
  }
}

export default useUtils
