import { getExternalHTML } from '../assets/resources/content'
import useContextAPI from './useContextAPI'
const useContent = () => {
  const { localization } = useContextAPI()
  const geExternalHTML = ({ content }) => {
    switch (content) {
      case 'viewDataCollected': {
        const content = getExternalHTML({
          language: localization.language,
          country: localization.country.toUpperCase()
        })
        return content
      }
    }
  }

  return {
    geExternalHTML
  }
}

export default useContent
