import styled, { css } from 'styled-components'
// You can use variables from veneer tokens and do something like the below
import Tokens from '@veneer/tokens'

const flexDirectionRow = css`
  display: flex;
  flex-direction: row;
`

export const Section = styled.div`
  position: relative;
  ${flexDirectionRow};
  margin: ${({ theme }) => (theme?.isMobile ? '0px' : `${Tokens.space3} 40px`)};
  flex: 1;
`

export const IFrame = styled.iframe`
  margin-top: ${({ theme }) => (theme?.isMobile ? '0px' : '-52px')};
  border: none;
  @media (min-width: ${Tokens.lgMin}) {
    ${(props) =>
      css`
        height: ${props.height - 155 + 'px'};
      `}
  }
  @media (max-width: ${Tokens.lgMax}) {
    ${(props) =>
      css`
        height: ${props.height - 125 + 'px'};
      `}
  }
`
