import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import RootProvider from '@jarvis/react-hpx-support-shared/dist/context/root/RootProvider'
import { useTheme } from '@veneer/theme'
import { ThemeProvider } from 'styled-components'
import { useMethoneClient } from '../client'
import useAuth from '../hooks/useAuth'
import useMethoneWin from '../client/methoneWin'
import { IMethoneClient } from '../hooks/useContextAPI'
import DeviceInfoProvider from './Device/index'
import UseDialogProvider from '@jarvis/react-hpx-support-shared/dist/components/Dialog/useDialogProvider'
import GlobalCSS from '../styles/global'
import useFeatureFlag from '../hooks/useFeatureFlag'
import usePlatforms from '../hooks/usePlatforms'
import useRouter from '../hooks/useRouter'
import useShared from '../hooks/useShared'

const RootComponent = ({
  shell,
  stack,
  navigation,
  localization,
  resources,
  dir,
  themeProvider
}) => {
  const { environment } = usePlatforms()
  const authProvider = useAuth()
  const sharedProvider = useShared()
  const featureFlag = useFeatureFlag({ environment })
  const { auth, callMiddleware, middleware } = authProvider
  const { MethoneClient, MethoneConfig }: IMethoneClient = useMethoneClient({
    stack,
    auth
  })
  const routersProvider = useRouter(environment)
  const MethoneWinClient = useMethoneWin({ stack, auth })
  const methoneProvider: any = {
    MethoneClient,
    MethoneConfig,
    MethoneWinClient
  }
  const theme = useTheme()

  const setAuth = (auth) => {
    if (auth) {
      MethoneClient.auth = auth
      MethoneWinClient.setAuth(auth)
    }
  }

  useEffect(() => {
    if (MethoneClient && MethoneWinClient && middleware?.current == null) {
      callMiddleware(({ auth }) => {
        setAuth(auth)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MethoneClient, MethoneWinClient])

  useEffect(() => {
    if (auth && MethoneClient && MethoneWinClient) {
      setAuth(auth)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, MethoneClient, MethoneWinClient])

  return (
    <>
      <ThemeProvider theme={{ ...theme, ...environment, dir }}>
        <GlobalCSS></GlobalCSS>
        <UseDialogProvider localization={localization}>
          {environment && methoneProvider && authProvider && (
            <RootProvider
              localization={localization}
              store={shell.store}
              resources={resources}
              methoneProvider={methoneProvider}
              authProvider={authProvider}
              sharedProvider={sharedProvider}
              themeProvider={themeProvider}
              environment={environment}
              navigation={navigation}
              featureFlag={featureFlag}
              dir={dir}
              routersProvider={routersProvider}
            >
              <BrowserRouter>
                <DeviceInfoProvider
                  shell={shell}
                  stack={stack}
                  navigation={navigation}
                />
              </BrowserRouter>
            </RootProvider>
          )}
        </UseDialogProvider>
      </ThemeProvider>
    </>
  )
}

export default RootComponent
