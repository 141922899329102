// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
String.prototype.capitalize = function () {
  try {
    return this.split(' ')
      .map((word, index) =>
        index > 0 && word !== word.toUpperCase()
          ? word[0].toLowerCase() + word.slice(1)
          : word
      )
      .join(' ')
  } catch {
    return this
  }
}
