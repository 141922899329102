import { vaData } from './assets/json/va'
import { VAType } from './components/Modals/types'

export interface IHardCodeResource {
  name: string
  title: string
  description: string
  context: string
  deepLink?: string
  automationID?: string
  isHide?: boolean
}
export interface IVAResource {
  name: string
  title: string
  description: string
  iconType: string
  context: string
  category: string
  hardCodeResources?: IHardCodeResource[]
  child?: string
  childRegedits?: []
  isHide?: boolean
}

/**
 * Get boolean value according to judgement
 * @param str unknown type, depended on you pass type of data
 * @returns boolean
 */
export function isEmpty(data: unknown) {
  // https://stackoverflow.com/questions/154059/how-do-i-check-for-an-empty-undefined-null-string-in-javascript
  if (typeof data === 'object') {
    if (JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]') {
      return true
    } else if (!data) {
      return true
    }
    return false
  } else if (typeof data === 'string') {
    if (!data.trim()) {
      return true
    }
    return false
  } else if (typeof data === 'undefined') {
    return true
  } else {
    return false
  }
}

/**
 * Get support status for VA, according to country and language arguments
 * @param country string
 * @param language string
 * @param locale string array
 * @param onlySupportENUS onlySupportENUS
 * @returns boolean
 */
export function IsSupportedForVisualAgent(
  country: string,
  language: string,
  locale: string,
  onlySupportENUS: boolean
) {
  if (isEmpty(country) && isEmpty(language) && isEmpty(locale)) {
    return false
  }

  const lc: string[] = vaData['languageCodes']
  const cs: string[] = vaData['countries']

  // TODO: In future it should be support lots of countries and languages.
  if (onlySupportENUS) {
    if (lc[1].includes(language) && cs[0].includes(country)) {
      console.log(
        `The support home page with entire section of Guided Troubleshooters: Account Support, MS 365, and 5G are all for US only for now`
      )
      return true
    } else {
      return false
    }
  }

  const localStr = locale.split('-')
  const appCountry = localStr.length > 2 ? localStr[2] : localStr[1]
  const appLanguage = localStr[0]
  const localeStr = appLanguage
    ?.toLowerCase()
    .concat(`-${appCountry?.toUpperCase()}`)
  // console.log('********locale condition is :' + localeStr + '*********')
  if (lc.includes(localeStr) && cs.includes(country)) {
    // matched either 'pt-PT' or 'zh-CN', in the future, perhaps more and more others support items.
    console.log('******Match data is :' + locale + '*****')
    return true
  }
  return lc.includes(language) && cs.includes(country)
}

/**
 * filter out data according to conditions
 * @param title is condition
 * @returns array
 */
function troubleShootingSupport(title: string): boolean {
  const filterConditions = [
    'common.dz_pcslowissue_Title',
    'common.dz_noPowerBoot_Title',
    'common.dz_soundIssue_Title',
    'common.dz_displayIssue_Title',
    'common.dz_pclockissue_Title',
    'common.dz_storageIssue_Title',
    'common.dz_recovery_Title',
    'common.dz_operatingSystem_Title',
    'common.dz_winloginissues_Title',
    'common.dz_kbMouseDT_Title',
    'common.dz_pts_wifi_Title',
    'common.dz_pcwireless_Title'
  ]
  return filterConditions.includes(title, 0)
}

/**
 * Get correct VA's links data according to some conditions.
 * @param t means translations
 * @returns list
 */
export async function getVAResources(
  t?: any
): Promise<IVAResource[] | undefined> {
  const vaResources: IVAResource[] = []
  const res: unknown[] = vaData.getResources(t)
  res.forEach(function (item) {
    if (!isEmpty(item['title']) && !troubleShootingSupport(item['title'])) {
      vaResources.push({
        name: item['name'],
        title: item['title'],
        description: item['description'],
        iconType: item['iconType'],
        context: item['context'],
        category: item['category'],
        hardCodeResources: item['hardCodeResources'],
        child: item['child'],
        childRegedits: item['childRegedits'],
        isHide: item['isHide']
      } as IVAResource)
    }
  })
  return vaResources
}

/**
 * HPXSUP-1966: Hide some not ready VA intents and resources for HPOne.
 */
export function getVAIntentsForHPOneList() {
  const accountSupport = []
  const msOneDrive = []
  const fiveGConnectivity = []

  const res: unknown[] = vaData.getResources(null)
  res.forEach((item: IVAResource) => {
    const isHideCondition = item.isHide === undefined || item.isHide === false
    if (item.category === VAType.VAASType && isHideCondition) {
      accountSupport.push(item)
    }
    if (item.category === VAType.VAMSType && isHideCondition) {
      msOneDrive.push(item)
    }
    if (item.category === VAType.VAFiveGType && isHideCondition) {
      fiveGConnectivity.push(item)
    }
  })
  const categoriesArr = {
    [VAType.VAASType]: accountSupport.length,
    [VAType.VAMSType]: msOneDrive.length,
    [VAType.VAFiveGType]: fiveGConnectivity.length
  }
  return categoriesArr
}
