import { useState } from 'react'

export interface IData {
  showBanner: boolean
}

const useShared = () => {
  const [data, _] = useState<IData>({
    showBanner: false
  })

  const setData = (param) => {
    _((prev) => (prev ? { ...prev, ...param } : prev))
  }
  return {
    data,
    setData
  }
}

export default useShared
