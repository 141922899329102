import { SHOW_PAGE_EVENT_NAME, SHOW_VA_MODAL_EVENT } from '../utils'
import { PUBLISHER_ID } from '../utils'
import { sendEvent } from '@jarvis/web-support-commons'

/**
 * Defined event listener
 * @param type sent event data
 * @param sessionTimeOut
 * @param show
 * @returns boolean
 */
export const useSupportSkeleton = () => {
  const hideSkelton = (type: string, sessionTimeOut: number, show: boolean) => {
    const wrapper: HTMLElement = document.querySelector(
      '#root-config-loader-wrapper'
    )
    if (wrapper) {
      wrapper.style.display = 'none'
    }
    sendEvent(
      SHOW_PAGE_EVENT_NAME,
      JSON.stringify({
        type: type,
        sessionTimeOut: sessionTimeOut,
        show: show
      }),
      PUBLISHER_ID
    )
    console.log(
      `${SHOW_PAGE_EVENT_NAME} has been send to React Native sides!!!`
    )
    const time = new Date()
    console.log(
      `==================End loading: ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}:${time.getMilliseconds()}==================`
    )
  }
  const showRNVAModal = () => {
    sendEvent(
      SHOW_VA_MODAL_EVENT,
      JSON.stringify({
        type: SHOW_VA_MODAL_EVENT,
        show: true
      }),
      PUBLISHER_ID
    )
    console.log(`${SHOW_VA_MODAL_EVENT} has been send to React Native sides!!!`)
  }
  return {
    hideSkelton,
    showRNVAModal
  }
}
