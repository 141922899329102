import styled, { css } from 'styled-components'
// You can use variables from veneer tokens and do something like the below
import Tokens from '@veneer/tokens'
import Button from '@veneer/core/dist/esm/scripts/button'
import Scrollbar from '@veneer/core/dist/esm/scripts/scrollbar'
import { withAnalytics } from '@veneer/analytics'
const TrackButton = withAnalytics(Button)
// overlaid the Tokens values
const fontStyle = 'normal'
const fontWeight = 400

const captionCSS = css`
  font-style: ${fontStyle};
  font-weight: ${fontWeight};
  font-size: ${Tokens.fontSize2};
  line-height: ${Tokens.lineHeight3};
  letter-spacing: ${Tokens.letterSpacing3};
`
const flexDirectionRow = css`
  display: flex;
  flex-direction: row;
`
const flexDirectionColumn = css`
  display: flex;
  flex-direction: column;
`

export const CardCenter = styled.div`
  ${flexDirectionRow};
  margin: ${Tokens.space3} 40px;
`
export const VaChatContainer = styled.div`
  display: ${(props) => (props.hide ? 'none' : 'block')};
`
export const CardCenterLine = styled.div`
  ${flexDirectionRow};
  margin: ${Tokens.space5} 40px ${Tokens.space3} 40px;
  border-bottom: 1px solid
    ${({ theme }) => theme?.colorForeground || Tokens.colorGray5};
  opacity: 0.6;
`

export const VAHeaderWrapper = styled.div`
  ${flexDirectionRow};
  margin: ${({ theme }) =>
    theme?.isMobile ? '8px 16px 16px 16px' : '0px 40px'};
`

export const VABodyWrapper = styled(Scrollbar)`
  position: relative;
  ${({ theme }) =>
    theme?.isMobile ? `${flexDirectionColumn}` : `${flexDirectionRow}`};
  justify-content: flex-start;
  width: ${({ theme }) => (theme?.isMobile ? 'auto' : 'calc(100% - 80px)')};
  margin: ${({ theme }) =>
    theme?.isMobile ? '8px 16px 16px 16px' : '18px 40px 5px 40px'};
`

export const VABoxLeft = styled.div`
  width: calc(100%);
  ${flexDirectionRow};
  align-items: flex-start;
  margin-bottom: 15px;
`

export const VABoxContainerRightWrapper = styled.div`
  ${flexDirectionColumn};
  width: calc(100%);
`

export const VABoxResources = styled.div`
  height: ${Tokens.space5};
  margin-bottom: 2px;
`

export const RightItemTitleOfSourcesLine = styled.div`
  opacity: 0.6;
  border-top: 1px solid
    ${({ theme }) => theme?.colorForeground || Tokens.colorGray5};
`

export const VABoxResourcesText = styled.div`
  color: ${({ theme }) => theme?.colorForeground || Tokens.colorGray10};
  ${captionCSS};
`

export const VABoxRightAction = styled.div`
  color: ${({ theme }) => theme?.colorForeground || Tokens.colorHpBlue6};
  ${captionCSS};
  .issueLink {
    color: ${({ theme }) => theme?.colorForeground || Tokens.colorHpBlue6};
  }
  .issueLink:hover {
    color: ${({ theme }) => theme?.colorForeground || Tokens.colorHpBlue7};
  }
  .issueLink:active {
    color: ${({ theme }) => theme?.colorForeground || Tokens.colorHpBlue8};
  }
  .issueLink:focus {
    color: ${({ theme }) => theme?.colorForeground || Tokens.colorHpBlue9};
  }
  a {
    cursor: pointer;
  }
`
export const VALinkButton = styled(TrackButton)`
  &.link {
    padding: 0 5px;
    text-align: left;
    white-space: inherit;
    min-width: 0;
    border-radius: 0;
    border: none;
    margin-right: 16px;
    ${captionCSS};
    > span {
      color: ${({ theme }) => theme?.colorPrimary};
    }
    :hover {
      color: ${({ theme }) => theme?.colorPrimaryHover};
      background-color: transparent;
      > span {
        color: ${({ theme }) => theme?.colorPrimaryHover};
      }
    }
    :active {
      color: ${({ theme }) => theme?.colorPrimaryActive};
      background-color: transparent;
      > span {
        color: ${({ theme }) => theme?.colorPrimaryActive};
      }
    }
    :focus {
      color: ${({ theme }) => theme?.colorPrimaryHover};
      background-color: transparent;
      > span {
        color: ${({ theme }) => theme?.colorPrimaryHover};
      }
    }
  }
`
export const VABoxRightT = styled.div`
  margin-top: ${Tokens.space3};
  ${(props) =>
    props?.resourceLength === 1 &&
    css`
      margin-bottom: ${Tokens.space3};
    `}
`

export const VAFooterWrapper = styled.div`
  ${flexDirectionColumn};
  justify-content: flex-start;
  width: ${({ theme }) => (theme?.isMobile ? 'auto' : 'calc(100% - 80px)')};
  margin: ${({ theme }) =>
    theme?.isMobile ? '8px 16px 16px 16px' : '0px 40px 32px 40px'};
  @media (max-width: ${Tokens.xsMax}) {
    padding-bottom: 10px;
  }
`

export const VAFooterLine = styled.div`
  border-top: 1px solid #cccccc;
  opacity: 0.6;
`

export const VAFooterIssueText = styled.div`
  margin-top: ${Tokens.space4};
`

export const VAFooterActions = styled.div`
  ${flexDirectionRow};
  justify-content: space-between;
  align-items: center;
  margin-top: ${Tokens.space4};
  @media (max-width: ${Tokens.smMax}) {
    ${flexDirectionColumn};
    align-items: flex-start;
  }
`

export const VAColImg = styled.div`
  display: flex;
`

export const VAColDesc = styled.div`
  display: inline-block;
  margin-left: ${Tokens.space3};
  color: ${({ theme }) => theme?.colorForeground};
  font-style: ${fontStyle};
  font-weight: ${fontWeight};
  font-size: ${Tokens.fontSize2};
  line-height: ${Tokens.lineHeight3};
  letter-spacing: ${Tokens.letterSpacing3};
`

export const VAColButton = styled.div`
  margin-left: ${Tokens.space1};
  display: flex;
  // width: calc(30%);
  ${flexDirectionRow};
  align-items: flex-end;
  justify-content: flex-end;
  @media (max-width: ${Tokens.smMax}) {
    width: calc(100%);
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: ${Tokens.space4};
  }
`

export const VABoxContainerWrapper = styled.div`
  ${(props) =>
    props?.calcItemWidth
      ? css`
          width: ${({ theme }) =>
            theme?.isMobile ? 'calc(100%)' : 'calc(80%)'};
        `
      : css`
          width: ${({ theme }) =>
            theme?.isMobile ? 'calc(100%)' : 'calc(50%)'};
        `}
`

export const VABoxRow = styled.div`
  ${flexDirectionRow};
  align-items: flex-start;
  ${(props) =>
    props.isFiveG &&
    css`
      align-items: center;
    `}
`

export const VABoxIconCol = styled.div`
  display: flex;
  margin-top: ${({ theme }) => (theme?.isMobile ? Tokens.space1 : '5px')};
`

export const VABoxTextCol = styled.div`
  cursor: pointer;
  margin-left: ${Tokens.space3};
  margin-top: ${({ theme }) => (theme?.isMobile ? Tokens.space1 : '5px')};
  ${(props) =>
    props.isFiveG &&
    css`
      margin-top: 0px;
    `}
`

export const VABoxColAction = styled.div`
  color: ${({ theme }) => theme?.colorForeground || Tokens.colorHpBlue6};
  font-style: ${fontStyle};
  font-weight: ${fontWeight};
  font-size: ${Tokens.fontSize2};
  line-height: ${Tokens.lineHeight3};
  letter-spacing: ${Tokens.letterSpacing3};

  .issueLink {
    color: ${({ theme }) => theme?.colorForeground || Tokens.colorHpBlue6};
  }
  .issueLink:hover {
    color: ${({ theme }) => theme?.colorForeground || Tokens.colorHpBlue7};
  }
  .issueLink:active {
    color: ${({ theme }) => theme?.colorForeground || Tokens.colorHpBlue8};
  }
  .issueLink:focus {
    color: ${({ theme }) => theme?.colorForeground || Tokens.colorHpBlue9};
  }
`

export const VAFooterIssueStrong = styled.strong`
  color: ${({ theme }) => theme?.colorForeground || Tokens.colorGray10};
  font-style: ${fontStyle};
  font-size: ${Tokens.fontSize2};
  line-height: ${Tokens.lineHeight3};
  letter-spacing: ${Tokens.letterSpacing3};
`

export const VAColImgAndDesc = styled.div`
  ${flexDirectionRow};
  align-items: center;
  width: calc(70%);
  @media (max-width: ${Tokens.smMax}) {
    width: calc(100%);
  }
  margin-right: ${Tokens.space8};
`

export const VAButton = styled(TrackButton)`
  @media (max-width: ${Tokens.smMax}) {
    min-width: calc(100%) !important;
  }
`

export const ImageContainer = styled.div`
  .fiveG {
    width: 25px;
    height: 30px;
    padding-top: ${Tokens.space2};
    color: ${({ theme }) => theme.colorForeground};
  }
`
