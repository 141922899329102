/**
 * :focus-within workaround for edge classic
 */
;((document) => {
  const EVENTS = ['keydown', 'mousedown', 'focus', 'blur']
  const DISABLE_KEY_CODE = [1, 3]
  let code = 0
  const handleEvent = (e) => {
    const elem = e.target as Element
    switch (e.type) {
      case 'focus': {
        if (
          elem.tagName.toLowerCase() === 'a' ||
          elem.classList.contains('cardStyle')
        ) {
          if (DISABLE_KEY_CODE.includes(code) == false)
            elem?.setAttribute('data-focus', 'true')
        }
        break
      }
      case 'blur': {
        elem?.removeAttribute('data-focus')
        break
      }
      case 'mousedown': {
        code = e.keyCode || e.which
        break
      }
      case 'keydown': {
        code = e.keyCode || e.which
        break
      }
    }
    //}
  }

  EVENTS.map((event) => document.addEventListener(event, handleEvent, true))

  window.addEventListener('unload', () => {
    EVENTS.map((event) =>
      document.removeEventListener(event, handleEvent, true)
    )
  })
})(document)
