import { IVAResource } from '../../vaJsonParser'
import {
  LinkingPlugin,
  launchUrlFromCommand
} from '@jarvis/web-support-commons'
import { VAType } from '../Modals/types/index'
import { EventEmitter } from 'events'
EventEmitter.defaultMaxListeners = 0
/**
 * According to category value try to get correct json data
 * @param data All of list data
 * @param category Include the AccountSupport,MSOneDrive,5GConnectivity, Printer_Category, Category
 * @param productSeriesOID According to productSeriesOID to distinguish different VA printer items.
 * @returns new Array
 */
export function filterLinks(
  data: IVAResource[],
  category: string,
  productSeriesOID?: string
): IVAResource[] {
  // console.log(category)
  if (!data || data.length <= 0) {
    throw new Error('No list data')
  }
  if (!category) {
    throw new Error('No category data')
  }
  const resources = data.filter(
    (item: IVAResource) => item.category === category
  )
  // TODO:
  // const resources = data.filter(
  //   (item: IVAResource) =>
  //     item.category === category &&
  //     (item.isHide === undefined || item.isHide === false)
  // )
  let links = resources
  // handle only for printer machine
  if (category === VAType.VAPrinterDiagnostics) {
    if (resources !== null && resources.length > 0) {
      links = []
      resources.forEach((item) => {
        if (!item?.child) links.push(item)
        if (item?.child && item?.childRegedits) {
          for (let i = 0; i < item?.childRegedits.length; i++) {
            if (item?.childRegedits[i] === Number(productSeriesOID)) {
              links.push(item)
              continue
            }
          }
        }
      })
    }
  }
  // console.log(links)
  return links
}

/**
 * Resources
 * @param data All of list data
 * @returns boolean
 */
export function isEmpty(data: IVAResource[]): boolean {
  const bool = data !== null && data?.length > 0
  return bool
}

/**
 * hardCodeResources
 * @param data All of list data
 * @returns boolean
 */
export function isChildResourceEmpty(data: IVAResource[]): boolean {
  let bool = false
  if (data !== null && data?.length > 0) {
    data.forEach((item: IVAResource) => {
      if (item.hardCodeResources !== undefined) {
        bool = true
        return
      }
    })
  }
  return bool
}
/**
 * Calculate whether to occupy the entire parent container
 * @param data All of list data
 * @returns boolean
 */
export function calcItemWidth(data: IVAResource[]): boolean {
  let bool = false
  if (data !== null && data.length <= 5 && !isChildResourceEmpty(data)) {
    bool = true
  }
  return bool
}

/**
 * open deep link
 * @param url deep link url
 * @param setLoading loading status
 * @param device info
 */
export async function openURL(url: string, setLoading, device) {
  setLoading('VA', true)
  if (url.startsWith('"HPSALauncher.exe"', 0)) {
    await launchUrlFromCommand(url, device)
  } else {
    await LinkingPlugin.openURL(url)
  }
  setTimeout(() => {
    // async task
    setLoading('VA', false)
  }, 700)
}

export const NoticeEmitter = new EventEmitter()

export function strLength(str, companyLength) {
  return str.length < companyLength
}

export function strIsAccord(str, companyLength) {
  if (typeof str == undefined || str == null || str == '') {
    return true
  } else {
    return strLength(str, companyLength)
  }
}

export function JudgeDisplayLeftOrRight(links: Array<any>) {
  if (links.length > 0 && links !== undefined) {
    if (links.length % 2 == 0) {
      return links.length / 2 - 1
    } else {
      return (links.length + 1) / 2 - 1
    }
  }
  return 0
}
