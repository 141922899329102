import { JARVIS_ENVIRONMENT } from '../../src/utils'

export interface IRoute {
  homePath: string | ''
  navigatePath: string | ''
  supportRoute: string | ''
  deviceDetailPath: string | ''
}

const useRouter = (environment) => {
  const basicUrl = '/:cc?/:lc?/'
  const supportUrl = 'support'
  const deviceUrl = 'device'
  const deviceParamUrl = `${deviceUrl}/:DeviceId?`

  const getRouteByPlatform = (): IRoute => {
    const homePath = `${basicUrl}${supportUrl}?/`
    const routeObj: IRoute = {
      homePath: homePath,
      navigatePath: supportUrl.concat(`/${deviceUrl}`),
      supportRoute: `/${supportUrl}`,
      deviceDetailPath: `${homePath}${deviceParamUrl}`
    }

    if (environment?.platform) {
      switch (environment?.platform) {
        case JARVIS_ENVIRONMENT.ANDROID:
          routeObj.homePath = `${basicUrl}${JARVIS_ENVIRONMENT.ANDROID}?/${supportUrl}?/`
          routeObj.supportRoute = `/${JARVIS_ENVIRONMENT.ANDROID}/${supportUrl}`
          routeObj.navigatePath = `${
            JARVIS_ENVIRONMENT.ANDROID
          }/${supportUrl.concat(`/${deviceUrl}`)}`
          routeObj.deviceDetailPath = `${basicUrl}${JARVIS_ENVIRONMENT.ANDROID}?/${supportUrl}?/${deviceParamUrl}/:page?/:subpage?`
          break
        case JARVIS_ENVIRONMENT.IOS:
          routeObj.homePath = `${basicUrl}${JARVIS_ENVIRONMENT.IOS}?/${supportUrl}?/`
          routeObj.supportRoute = `/${JARVIS_ENVIRONMENT.IOS}/${supportUrl}`
          routeObj.navigatePath = `${
            JARVIS_ENVIRONMENT.IOS
          }/${supportUrl.concat(`/${deviceUrl}`)}`
          routeObj.deviceDetailPath = `${basicUrl}${JARVIS_ENVIRONMENT.IOS}?/${supportUrl}?/${deviceParamUrl}/:page?/:subpage?`
          break
        default:
          break
      }
    }
    return routeObj
  }

  const choosePlatformMFEs = () => {
    let pf = ''
    switch (environment?.platform) {
      case JARVIS_ENVIRONMENT.ANDROID:
        pf = `${JARVIS_ENVIRONMENT.ANDROID.concat('-')}`
        break
      case JARVIS_ENVIRONMENT.IOS:
        pf = `${JARVIS_ENVIRONMENT.IOS.concat('-')}`
        break
      default:
        break
    }
    return pf
  }

  return {
    choosePlatformMFEs: choosePlatformMFEs,
    getRouteByPlatform: getRouteByPlatform
  }
}

export default useRouter
