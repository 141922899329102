import { deviceInfo, getAuthToken, IAppInfo } from '@jarvis/web-support-commons'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import { IRequestBody, requestParams, JARVIS_ENVIRONMENT } from '../utils'
import { CLIENT_IDS } from '../client/config'
import { IVAResponse } from '../components/Modals/types/index'
import useDialog from '@jarvis/react-hpx-support-shared/dist/hooks/useDialog'
import useModal from '@jarvis/react-hpx-support-shared/dist/hooks/useModal'
import useContextAPI from './useContextAPI'
import useOptin from '../hooks/useOptin'
import { IRoute } from '../hooks/useRouter'
import { POLY_PRODUCT_TYPE } from '../utils/device'

const useVA = () => {
  const { t } = useI18n()
  const { openDialog, closeDialog } = useDialog()
  const { updateModal } = useModal()
  const optinProvider = useOptin()
  const { optin } = optinProvider || {}
  const {
    authProvider,
    environment,
    localization,
    methoneProvider,
    navigation,
    routersProvider
  } = useContextAPI()
  const { isSignedIn, auth, profile } = authProvider
  const { MethoneClient, MethoneConfig } = methoneProvider
  const { platform, isNative, isMobile } = environment
  const { getRouteByPlatform } = routersProvider
  const route: IRoute = getRouteByPlatform()

  const getVAConfig = (props) => {
    const { setLoading, stack, device } = props
    async function getBotLaunchUrl(query, isSignIn, optiForCB = false) {
      const isLoggedIn = isSignedIn || (isSignIn ? await getAuthToken() : true)
      // If user not login, don't call bot/conversation API.
      if (!isLoggedIn && platform !== JARVIS_ENVIRONMENT.WEB && isNative) {
        return false
      }

      const { country, language } = device?.DeviceInfo?.locale || localization
      setLoading('VA', true)

      const appInfo = (await deviceInfo.getAppInfo()) as IAppInfo
      const VAOSVersion = (await deviceInfo.getVAOSVersion(
        !device?.IsChromebook || POLY_PRODUCT_TYPE.includes(device?.ProductType)
          ? false
          : true
      )) as string
      const userAccount = {
        accountId: profile?.Id,
        emailAddress: profile?.Email,
        familyName: profile?.LastName,
        givenName: profile?.FirstName
      }
      const requestBody: IRequestBody = {
        sn: device?.SerialNumber,
        pn: device?.ProductNumber,
        country,
        language,
        appVersion: appInfo?.appVersion,
        platForm: appInfo?.platform,
        query,
        isSignedIn,
        tokenValue: auth?.token,
        userAccount,
        stack,
        osVersion: VAOSVersion,
        device
      }
      const data = MethoneClient?.getBotConversationLaunchUrl(
        await requestParams(requestBody, optin || optiForCB),
        CLIENT_IDS.HPX,
        requestBody?.language === undefined ? 'en' : requestBody.language,
        requestBody?.country === undefined ? 'us' : requestBody.country
      )
      data.then(onResolution, onRejection)
    }

    function onRejection(error: TypeError) {
      setLoading('VA', false)
      console.log(`${error.message}`)
    }

    function onResolution(data: IVAResponse) {
      const vaUrl = MethoneConfig['VA_URL']?.concat(data.launchUrl)
      setLoading('VA', false)

      if (isMobile) {
        // navigation.push(`/support/virtualassistant`, {
        navigation.push(`${route?.supportRoute}/virtualassistant`, {
          vaUrl: vaUrl,
          navBarTitle: 'common.virtualAssistant'
        })
      } else {
        updateModal('VA', {
          isOpen: true,
          sessionStatus: true,
          width: '75%',
          resize: false,
          category: {
            type: props.type,
            contextTitle: t('common.hpVirtualAssistant')
          },
          config: {
            ...props?.config,
            [props?.type]: { isOpen: true, vaUrl: vaUrl }
          }
        })
      }
    }

    return { getBotLaunchUrl }
  }

  const confirmDialog = ({ callbackYes, callbackNo }) => {
    openDialog({
      title: t('common.endVirtualAssistantSession'),
      content: t('common.areYouSureCancelVASession'),
      align: 'left',
      okText: t('common.noKeepSession'),
      okCallback: () => {
        closeDialog()
        callbackYes?.()
      },
      cancelText: t('common.closeSessionLowerCase'),
      cancelCallback: () => {
        closeDialog()
        callbackNo?.()
      }
    })
  }

  return {
    getVAConfig,
    confirmDialog,
    closeDialog
  }
}

export default useVA
