import React, { useState, useEffect, forwardRef } from 'react'
import { useLocation } from 'react-router-dom'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import * as S from './styles'
import { VASmileBlue } from '../../../assets/images'
import { VAChat } from '../VAChat/index'
import { VAType } from '../../Modals/types/index'
import { getVAResources, IVAResource } from '../../../vaJsonParser'
import {
  filterLinks,
  isEmpty,
  isChildResourceEmpty,
  openURL,
  calcItemWidth
} from '../utils'
import { FiveGSVG } from '../../../assets/images'
import { formatAutoId } from '../../../utils'
import CustomImage from '@veneer/core/dist/esm/scripts/custom_image'
import useVA from '../../../hooks/useVA'
import useContextAPI from '../../../hooks/useContextAPI'
import { getSupportConsent } from '@jarvis/web-support-commons/dist/privacy'
import useModal from '@jarvis/react-hpx-support-shared/dist/hooks/useModal'
import useDialog from '@jarvis/react-hpx-support-shared/dist/hooks/useDialog'

const VATypes = [VAType.VAASType, VAType.VAFiveGType, VAType.VAMSType]
const iconsMap = {
  // eslint-disable-next-line react/display-name
  fiveG: () => {
    return (
      <S.ImageContainer>
        <CustomImage viewBox="0 0 30 25" className="fiveG" role="img">
          <FiveGSVG />
        </CustomImage>
      </S.ImageContainer>
    )
  },
  VASmileBlue: () => {
    return (
      <CustomImage viewBox="0 0 36 36" role="img" size={36}>
        <VASmileBlue />
      </CustomImage>
    )
  }
}

const VABoxContainerLeft = (listLinks: IVAResource[], onChangeContextTitle) => {
  return (
    <S.VABoxContainerWrapper calcItemWidth={calcItemWidth(listLinks)}>
      {isEmpty(listLinks) &&
        listLinks.map(
          (item: IVAResource, index: number) =>
            index <= 5 && (
              <S.VABoxLeft key={item.context}>
                <S.VABoxRow isFiveG={item.iconType === ''}>
                  <S.VABoxIconCol>
                    {item.iconType === '' ? iconsMap.fiveG() : item.iconType}
                  </S.VABoxIconCol>
                  <S.VABoxTextCol isFiveG={item.iconType === ''}>
                    <S.VABoxColAction className="caption">
                      <S.VALinkButton
                        small={true}
                        appearance="ghost"
                        tabIndex={0}
                        aria-label={item.title}
                        className="link"
                        id={formatAutoId(
                          `VAModal_list_issueItem_${item.context}`
                        )}
                        onClick={() => onChangeContextTitle(item.context)}
                      >
                        {item.title}
                      </S.VALinkButton>
                    </S.VABoxColAction>
                  </S.VABoxTextCol>
                </S.VABoxRow>
              </S.VABoxLeft>
            )
        )}
    </S.VABoxContainerWrapper>
  )
}

const VABoxContainerRight = (
  listLinks: IVAResource[],
  onChangeContextTitle,
  setLoading,
  device,
  confirmDialog,
  closeDialog,
  modals,
  closeAllModal,
  closeModal
) => {
  const { t } = useI18n()
  // Multiple-conditions
  function hardCodeResourcesCondition(item) {
    return (
      item.hardCodeResources !== undefined &&
      item.hardCodeResources !== null &&
      item.hardCodeResources.length > 0
    )
  }

  function openURLWithKeepSession(deepLink, setLoading, device) {
    for (const key in modals) {
      if (modals[key]?.sessionStatus) {
        confirmDialog({
          callbackYes: () => {
            closeDialog()
          },
          callbackNo: () => {
            closeDialog()
            closeModal(key)
            closeAllModal()
            openURL(deepLink, setLoading, device)
          }
        })
        return false
      } else {
        openURL(deepLink, setLoading, device)
        return false
      }
    }
  }

  return (
    <S.VABoxContainerWrapper>
      <S.VABoxContainerRightWrapper>
        {isEmpty(listLinks) &&
          listLinks.map(
            // index that beginning from 0
            (item: IVAResource, index: number) =>
              index >= 6 && (
                <S.VABoxLeft key={item.context}>
                  <S.VABoxRow>
                    <S.VABoxIconCol>{item.iconType}</S.VABoxIconCol>
                    <S.VABoxTextCol>
                      <S.VABoxColAction className="caption">
                        <S.VALinkButton
                          small={true}
                          appearance="ghost"
                          aria-label={item.title}
                          tabIndex={0}
                          className="link"
                          id={formatAutoId(
                            `VAModal_list_issueItem_${item.context}`
                          )}
                          onClick={() => onChangeContextTitle(item.context)}
                        >
                          {item.title}
                        </S.VALinkButton>
                      </S.VABoxColAction>
                    </S.VABoxTextCol>
                  </S.VABoxRow>
                </S.VABoxLeft>
              )
          )}
        {isChildResourceEmpty(listLinks) && (
          <>
            <S.VABoxResources>
              <S.VABoxResourcesText className="caption">
                {t('common.resources')}
              </S.VABoxResourcesText>
            </S.VABoxResources>
            <S.RightItemTitleOfSourcesLine />
          </>
        )}
        {isEmpty(listLinks) &&
          listLinks.map(
            (item: IVAResource) =>
              hardCodeResourcesCondition(item) &&
              item?.hardCodeResources.map((childItem: any) => (
                <S.VABoxContainerRightWrapper key={childItem?.context}>
                  <S.VABoxRightT
                    resourceLength={item?.hardCodeResources?.length}
                  >
                    <S.VABoxRightAction className="caption">
                      <S.VALinkButton
                        small={true}
                        appearance="ghost"
                        aria-label={childItem.title}
                        className="link"
                        tabIndex={0}
                        id={formatAutoId(
                          `VAModal_list_issueItem_${childItem?.automationID}`
                        )}
                        onClick={() =>
                          openURLWithKeepSession(
                            childItem.deepLink,
                            setLoading,
                            device
                          )
                        }
                        style={{ paddingLeft: 0 }}
                      >
                        {childItem.title}
                      </S.VALinkButton>
                    </S.VABoxRightAction>
                  </S.VABoxRightT>
                </S.VABoxContainerRightWrapper>
              ))
          )}
      </S.VABoxContainerRightWrapper>
    </S.VABoxContainerWrapper>
  )
}

const VAContainer = (props) => {
  const { t } = useI18n()
  const { getVAConfig } = useVA()
  const { listLinks, type } = props

  const onChangeContextTitle = async (query = '') => {
    const { getBotLaunchUrl } = getVAConfig(props)
    await getBotLaunchUrl(query, true)
  }

  return (
    <>
      <S.VAHeaderWrapper
        tabIndex={'-1'}
        id={formatAutoId('VAModal_header_title')}
      >
        <span className="caption">
          {type === VAType.VAASType && t('common.selectVAOptionsBelowAccount')}
          {type === VAType.VAMSType &&
            t('common.ourVACanHelpAndSolveMSCloudStorageIssues')}
          {type === VAType.VAFiveGType && t('common.selectVAOptionsBelow5G')}
        </span>
      </S.VAHeaderWrapper>
      <S.VABodyWrapper tabIndex={-1}>
        {VABoxContainerLeft(listLinks, onChangeContextTitle)}
        {!calcItemWidth(listLinks) &&
          VABoxContainerRight(
            listLinks,
            onChangeContextTitle,
            props?.setLoading,
            props?.device,
            props?.confirmDialog,
            props?.closeDialog,
            props?.modals,
            props?.closeAllModal,
            props?.closeModal
          )}
      </S.VABodyWrapper>
      <S.VAFooterWrapper>
        <S.VAFooterLine />
        <S.VAFooterIssueText
          tabIndex={'-1'}
          id={formatAutoId('VAModal_footer_issue_text')}
        >
          <S.VAFooterIssueStrong className="caption-medium">
            {t('common.haveDifferentIssue')}
          </S.VAFooterIssueStrong>
        </S.VAFooterIssueText>
        <S.VAFooterActions>
          <S.VAColImgAndDesc>
            <S.VAColImg>{iconsMap.VASmileBlue()}</S.VAColImg>
            <S.VAColDesc
              tabIndex={'-1'}
              id={formatAutoId('VAModal_footer_issue_description')}
            >
              <span className="caption">
                {t('common.ifYourIssueIsntListVAOfferMoretourbleshooting')}
              </span>
            </S.VAColDesc>
          </S.VAColImgAndDesc>
          <S.VAColButton>
            <S.VAButton
              small={true}
              id={formatAutoId('VAModal_startVirtualAssistantButton')}
              appearance="secondary"
              className="secondary"
              onClick={() => onChangeContextTitle()}
            >
              {t('common.chatNow')}
            </S.VAButton>
          </S.VAColButton>
        </S.VAFooterActions>
      </S.VAFooterWrapper>
    </>
  )
}

// eslint-disable-next-line react/display-name
const SupportHomeVA = forwardRef((props: any) => {
  const [listLinks, setListLinks] = useState<IVAResource[]>(null)
  const { environment } = useContextAPI()
  const { isMobile } = environment || {}
  const { t } = useI18n()
  const { state } = useLocation()
  const config = props?.config ? props?.config : isMobile ? state?.config : {}
  const type = isMobile ? state?.type || VATypes[0] : props?.type
  props = isMobile ? { device: state?.device, ...props } : props
  const { getVAConfig, confirmDialog } = useVA()
  const { closeDialog } = useDialog()
  const { modals, closeAllModal, closeModal } = useModal()

  useEffect(() => {
    if (props?.auth === null) props?.closeAllModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.auth])

  useEffect(() => {
    const fetchData = async () => {
      const optin = await getSupportConsent()
      const data = (await getVAResources(t)) as IVAResource[]
      const listLinks = filterLinks(data, type || state?.category)
      // If current device has only one VA intent, we don't need to show guided troubleshooting data list in VA dialog,
      // It will just directly open VA conversation page with Methone API calling.
      if (listLinks !== null && listLinks.length === 1) {
        if (!config?.[type]?.isOpen) {
          const { getBotLaunchUrl } = getVAConfig(props)
          await getBotLaunchUrl(listLinks[0]?.context, false, optin)
        } else {
          const { updateModal } = props
          const VA = {
            hide: false
          }
          updateModal(VAType.VAFlag, { ...VA })
        }
      } else {
        setListLinks(listLinks)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, config?.[type]?.isOpen])

  const restProps = () => {
    return {
      ...props,
      type,
      listLinks: listLinks,
      confirmDialog,
      closeDialog,
      modals,
      closeAllModal,
      closeModal
    }
  }

  return (
    <>
      {config &&
        Object.keys(config)?.map((key) => (
          <>
            {!config?.[key]?.isOpen && key == type && (
              <VAContainer {...restProps()} />
            )}
          </>
        ))}
      {VATypes?.map((key) => (
        <>
          {config?.[key]?.vaUrl && (
            <S.VaChatContainer hide={type !== key}>
              <VAChat type={type} vaUrl={config?.[key]?.vaUrl} />
            </S.VaChatContainer>
          )}
        </>
      ))}
    </>
  )
})

export default React.memo(SupportHomeVA)
