import { useEffect, useRef, useState } from 'react'
import { getSupportConsent } from '@jarvis/web-support-commons'
import {
  getDevicesMock,
  getHostPCInfo,
  formatDeviceList,
  removeDeviceList,
  getImage
} from '../utils'
import useContextAPI from './useContextAPI'
import { matchPath, useLocation, useSearchParams } from 'react-router-dom'
import { JARVIS_ENVIRONMENT, initialState } from '../utils'
import { IDevice } from '@jarvis/web-support-commons/dist/methone'
import { IRoute } from '../hooks/useRouter'
import { isJobAlreadyStarted } from '../utils/device'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'

const useDevices = () => {
  const [loading, setLoading] = useState(false)
  const [device, setDevice] = useState(null)
  const [hostDevice, setHostDevice] = useState(null)
  const [devices, setDevices] = useState(null)
  const cancelCallRef = useRef(false)
  const {
    methoneProvider,
    authProvider,
    localization,
    navigation,
    environment,
    featureFlag,
    routersProvider
  } = useContextAPI()
  const location = useLocation()
  const { platform } = environment
  const { MethoneWinClient } = methoneProvider
  const { isSignedIn, auth, refreshToken } = authProvider
  const { deviceListEnabled, isSupportHomeEnabled } = featureFlag
  const { pathname } = location || {}
  const { getRouteByPlatform } = routersProvider
  const route: IRoute = getRouteByPlatform()
  const [searchParams] = useSearchParams()
  const { t } = useI18n()

  useEffect(() => {
    const fetchHostDevice = async () => {
      const start = performance.now()
      const hostDevice = await getHostPCInfo(t)
      setHostDevice(hostDevice)
      setDevices(hostDeviceCallback(hostDevice))
      getDeviceListWarranty([hostDevice])
      console.log(
        `==================>>>>>>>>>>>>>>>> fetchHostDevice cost: ${
          performance.now() - start
        }ms`
      )
    }
    fetchHostDevice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (hostDevice?.ProductNumber)
      fetchHostDetails(hostDevice?.ProductNumber, hostDevice?.SerialNumber)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostDevice?.ProductNumber])

  /** update host device data like image, oid info in devcie list */
  useEffect(() => {
    if (devices && loading == false) {
      const hostPCInfo = {
        ...(hostDevice?.ImageLink && {
          ImageLink: getImage(hostDevice?.ImageLink)
        }),
        ProductBigSeriesOID: hostDevice?.ProductBigSeriesOID,
        ProductSeriesOID: hostDevice?.ProductSeriesOID,
        ProductNameOID: hostDevice?.ProductNameOID
      }
      updateDeviceListBySN({
        ...hostPCInfo,
        SerialNumber: hostDevice.SerialNumber
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, devices?.length, hostDevice?.ImageLink])

  useEffect(() => {
    const fetchDeviceList = async () => {
      const start = performance.now()

      if (
        platform === JARVIS_ENVIRONMENT.WINDOWS ||
        platform === JARVIS_ENVIRONMENT.IOS ||
        platform === JARVIS_ENVIRONMENT.ANDROID
      ) {
        if (auth && isSignedIn) {
          try {
            if (deviceListEnabled) {
              const isJobStarted =
                searchParams.get('jobStarted') === 'true' ||
                (await isJobAlreadyStarted())
              console.log(`jobStarted value:${isJobStarted}`)
              if (!isJobStarted) {
                await MethoneWinClient?.startAsyncJob()
                window.sessionStorage.setItem('jobStarted', 'true')
              }

              getDeviceList({ jobStartViaMFE: !isJobStarted, cache: false })
            }
          } catch (e) {
            console.log(e)
            setLoading(false)
          }
        }
      } else {
        setLoading(true)
        setTimeout(async () => {
          const response = getDevicesMock()
          const devices = await formatDeviceList(
            response,
            hostDevice,
            isSignedIn,
            t
          )
          setDevices(devices)
          getDeviceListWarranty(devices)
          setLoading(false)
        }, 5000)
      }
      console.log(
        `==================>>>>>>>>>>>>>>>> fetchDeviceList cost: ${
          performance.now() - start
        }ms`
      )
    }
    /* FeatureSwitch: call Device List API if Device List feature enabled  */
    if (platform) fetchDeviceList()

    // return () => {
    //   stratusEvent?.current?.remove?.()
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform, auth?.type, isSignedIn, deviceListEnabled])

  // hook to set selected device
  useEffect(() => {
    if (
      devices?.length > 0 &&
      loading == false &&
      pathname?.includes(route?.navigatePath)
      // pathname?.includes('support/device')
    ) {
      selectDevice()
    }
    async function selectDevice() {
      const params = getParams()

      const deviceId = params?.DeviceId
      if (deviceId) {
        //if (device?.DeviceId != deviceId && device?.SerialNumber != deviceId) {
        const _device = devices.find(
          (device) =>
            device.DeviceId == deviceId || device.SerialNumber == deviceId
        )

        if (_device) {
          setDevice((prevState) =>
            _device?.SerialNumber === prevState?.SerialNumber
              ? {
                  ...prevState,

                  ..._device
                }
              : _device
          )
        } else {
          devices.forEach((device) => {
            const bundleDevices =
              device?.Subscriptions?.[0]?.Entities?.[0]?.LinkedEntities

            if (bundleDevices) {
              const _device = bundleDevices?.find(
                (device) =>
                  device.DeviceId == deviceId || device.SerialNumber == deviceId
              )

              if (_device) {
                setDevice({ ..._device, ProductName: _device.Name })

                return
              }
            }
          })
        }
        //}
      } else {
        navigateToHost()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname, devices, loading])

  useEffect(() => {
    if (auth === null && hostDevice) {
      cancelCallRef.current = true
      setLoading(false)
      setDevices([hostDevice])
      getDeviceListWarranty([hostDevice])
      const params = getParams()
      if (
        params?.DeviceId &&
        params?.DeviceId != hostDevice.SerialNumber &&
        !isSupportHomeEnabled
      ) {
        // navigation.push(`/support`, { skipBlock: true })
        navigation.push(route?.supportRoute, {
          skipBlock: true,
          navBarTitle: 'common.deviceSupport'
        })
      } else {
        if (device && device?.SerialNumber === hostDevice?.SerialNumber) {
          setDeviceDetails({
            ...hostDevice,
            DeviceId: undefined
          })
        }
      }
      removeDeviceList()
      window.sessionStorage.setItem('jobStarted', 'false')
    } else {
      cancelCallRef.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  useEffect(() => {
    const fecthSubscriptionInfo = async () => {
      const start = performance.now()
      if (device?.IsSubscriptionDevice) {
        if (isSignedIn)
          setDeviceDetails({
            subscriptionInfo: {
              supportCode: device?.SupportCode,
              subscriptionStatus: device?.Subscriptions?.[0]?.Status,
              subscriptionId:
                device?.Subscriptions?.[0]?.FriendlySubscriptionId,
              subscriptionStartDate: device?.Subscriptions?.[0]?.DateCreated
            },
            warrantyInfo: initialState?.warrantyInfo,
            loading: false
          })
        else {
          setDeviceDetails({
            subscriptionInfo: initialState?.subscriptionInfo,
            warrantyInfo: initialState?.warrantyInfo,
            loading: false
          })
        }
      }
      console.log(
        `==================>>>>>>>>>>>>>>>> fecthSubscriptionInfo cost: ${
          performance.now() - start
        }ms`
      )
    }
    fecthSubscriptionInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn, device?.Subscriptions, device?.SerialNumber])

  useEffect(() => {
    const fetchWarranty = async () => {
      const start = performance.now()
      const optin = await getSupportConsent()
      if (!device?.IsSubscriptionDevice) {
        if (optin) {
          getWarrantyInfo(
            device?.SerialNumber,
            device?.ProductNumber?.split('#')[0],
            localization?.locale,
            localization?.country,
            optin
          )
        } else {
          setDeviceDetails({
            warrantyInfo: initialState?.warrantyInfo,
            loading: false
          })
        }
      }
      console.log(
        `==================>>>>>>>>>>>>>>>> fetchWarranty cost: ${
          performance.now() - start
        }ms`
      )
    }
    if (device?.SerialNumber) fetchWarranty()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device?.SerialNumber, location?.pathname])

  const fetchHostDetails = async (productNumber, serialNumber) => {
    console.log('fetchHostDetails')
    await MethoneWinClient.getDeviceImage(productNumber, serialNumber)
    const oidInfo = await MethoneWinClient.getOID(productNumber, serialNumber)
    const hostPCInfo = {
      ImageLink: getImage(oidInfo?.ImageLinkCF),
      ProductBigSeriesOID: oidInfo?.ProductBigSeriesOID,
      ProductSeriesOID: oidInfo?.ProductSeriesOID,
      ProductNameOID: oidInfo?.ProductNameOID,
      OIDInfoReady: true
    }
    setHostDevice((prevState) =>
      prevState
        ? {
            ...prevState,
            ...hostPCInfo
          }
        : { ...hostPCInfo }
    )
    updateDeviceListBySN({ ...hostPCInfo, SerialNumber: serialNumber })
  }

  const getWarrantyInfo = async (
    SerialNumber,
    ProductNumber,
    Locale,
    CountryCode,
    userOptin
  ) => {
    try {
      setDeviceDetails({ loading: true })
      console.log(`-------getWarrantyInfo start---------`)
      const warrantyInfo = await MethoneWinClient.getWarrantyInfo({
        SerialNumber,
        ProductNumber,
        Locale,
        CountryCode,
        userOptin
      })
      setDeviceDetails({ warrantyInfo, loading: false })
      console.log(warrantyInfo)
      console.log(`-------getWarrantyInfo end---------`)
      return warrantyInfo
    } catch {
      setDeviceDetails({
        warrantyInfo: initialState?.warrantyInfo,
        loading: false
      })
    }
  }

  function setDeviceDetails(param) {
    if (device)
      setDevice((prevState) => {
        return prevState ? { ...prevState, ...param } : { ...param }
      })
  }

  function updateDeviceListBySN(param) {
    setDevices((prevState) => {
      return prevState?.map((device) => {
        if (device.SerialNumber === param.SerialNumber) {
          return { ...device, ...param }
        } else {
          return device
        }
      })
    })

    if (device?.SerialNumber === param?.SerialNumber) {
      setDeviceDetails(param)
    }
  }

  async function navigateToHost() {
    const hostPCInfo = hostDevice || (await getHostPCInfo(t))
    // navigation.push(`/support/device/${hostPCInfo?.SerialNumber}`, {
    navigation.push(`/${route?.navigatePath}/${hostPCInfo?.SerialNumber}`, {
      device: hostPCInfo,
      navBarTitle: 'common.deviceSupport'
    })
  }

  function getParams() {
    const { params } =
      matchPath(
        {
          path: route?.deviceDetailPath
            ?.replace(':page?/:subpage?', '*')
            .replace(/\?/g, '')
          // path: '/:cc/:lc/support/device/:DeviceId'
        },
        location?.pathname
      ) || {}
    return params
  }

  async function getDeviceListWarranty(devices) {
    if (devices.length > 2 || isSupportHomeEnabled) return
    /* call warranty if only 2 or less devices */
    const optin = await getSupportConsent()
    if (optin) {
      devices.forEach(async (device) => {
        if (device.IsSubscriptionDevice) return device
        const lastCheck = new Date(device?.WarrantyLastCheck)?.toDateString()
        const today = new Date().toDateString()
        if (lastCheck != today && !device?.IsSubscriptionDevice) {
          try {
            updateDeviceListBySN({
              WarrantyLoading: true,
              SerialNumber: device?.SerialNumber
            })
            const request = {
              SerialNumber: device?.SerialNumber,
              ProductNumber: device?.ProductNumber,
              Locale: localization?.locale,
              CountryCode: localization?.country
            }
            const warrantyInfo = await MethoneWinClient.getWarrantyInfo({
              ...request,
              userOptin: optin
            })
            updateDeviceListBySN({
              WarrantyLoading: false,
              WarrantyLastCheck: warrantyInfo?.userCheckDate,
              WarrantyEndDate: warrantyInfo?.warrantyEndDate,
              WarrantyStatus: warrantyInfo?.status,
              WarrantyState: warrantyInfo?.state,
              carepack: warrantyInfo?.carepack,
              SerialNumber: device?.SerialNumber
            })
          } catch {
            updateDeviceListBySN({
              WarrantyLoading: false,
              SerialNumber: device?.SerialNumber
            })
          }
        }
      })
    }
  }

  async function getDeviceList({
    jobStartViaMFE,
    cache = false,
    loader = true
  }) {
    try {
      setLoading(loader)
      let response = null
      if (isSupportHomeEnabled) {
        response = await MethoneWinClient.getDevicesCache()
      }
      if (!response) {
        const request = {
          ignoreDeviceChanges: false,
          enableStratusCall: deviceListEnabled,
          jobStartViaMFE: jobStartViaMFE,
          jobStatusCheckInterval: 1,
          cache
        }
        response = await MethoneWinClient?.getDevices?.(request)
      }
      await getFormatedDeviceList(response)
      setLoading(false)
    } catch (e) {
      console.log(e)
      await getFormatedDeviceList({ DeviceList: [] })
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  async function refreshDeviceList() {
    try {
      await refreshToken()
      const response = await MethoneWinClient.refershDevices(
        deviceListEnabled,
        3
      )
      window.sessionStorage.setItem('jobStarted', 'true')
      console.log('refreshDeviceList', response)
      const devices = await getFormatedDeviceList(response)
      setLoading(false)
      return devices
    } catch (e) {
      console.log('refreshDeviceList error', e)
      setLoading(false)
      return null
    }
  }

  async function getFormatedDeviceList(response) {
    if (cancelCallRef.current) return
    const devices: IDevice[] = await formatDeviceList(
      response,
      hostDevice,
      isSignedIn,
      t
    )
    if (devices?.length > 0) {
      setDevices(devices)
      getDeviceListWarranty(devices)
    }
  }

  function hostDeviceCallback(hostDevice) {
    return (prevState) => {
      if (prevState) {
        const isHostInProfile = prevState?.some(
          (device) => device.SerialNumber === hostDevice.SerialNumber
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        return isHostInProfile ? prevState : [hostDevice, ...prevState]
      } else {
        return [hostDevice]
      }
    }
  }

  return {
    device,
    devices,
    hostDevice,
    loading,
    updateDeviceListBySN,
    getWarrantyInfo,
    refreshDeviceList,
    getDeviceListWarranty
  }
}

export default useDevices
