export type ContextTitleProps = {
  contextTitle: string
}

/**
 * Responses of HTTP
 */
export interface IVAResponse {
  // Reserved for future use. Errors code is provided in FaultItemList
  errorCode: string | number
  // Reserved for future use. Errors code is provided in FaultItemList
  errorMessage: string
  // The token returned with launchUrl is valid for 30 mins to start the conversation.
  launchUrl: string
  // The statusCode of response
  statusCode?: number
}

export enum VAType {
  VAASType = 'AccountSupport',
  VAMSType = 'MSOneDrive',
  VAFiveGType = '5GConnectivity',
  VADiagnostics = 'Diagnostics',
  VAFlag = 'VA',
  ADD_DEVICE = 'addDevice',
  SUPPORT_HOURS = 'supportHours',
  CREATE_CASE = 'createCase',
  VAPrinterDiagnostics = 'Printer_Diagnostics',
  HPAllInPlanSupportPeople = 'HPAllInPlanSupportPeople',
  PrinterAndInkSupport = 'PrinterAndInkSupport'
}

export enum JSType {
  Object = 'object'
}
