import React from 'react'
import Dashboard from '../Dashboard'
import useDevices from '../../hooks/useDevices'
import useArrowKeyNavigation from '@jarvis/react-hpx-support-shared/dist/hooks/useArrowKeyNavigation'
import { ModalContextProvider } from '@jarvis/react-hpx-support-shared/dist/context/modal'
import projectNames from '../../configs/projectNames'
import ModalLoader from '../../components/Modals/ModalLoader'

const selectors =
  '[tabindex="0"]:not(.simplebar-content-wrapper), button:not([disabled]), a, input:not([type="hidden"])'

const DeviceInfoProvider = ({ shell, stack, navigation }) => {
  const {
    device,
    devices,
    hostDevice,
    loading,
    updateDeviceListBySN,
    getWarrantyInfo,
    refreshDeviceList,
    getDeviceListWarranty
  } = useDevices()
  const parentRef = useArrowKeyNavigation({ selectors })

  return (
    <div ref={parentRef} tabIndex={-1} id={`${projectNames.namespace}_body`}>
      <ModalContextProvider>
        <>
          <Dashboard
            stack={stack}
            navigation={navigation}
            device={device}
            hostDevice={hostDevice}
            devices={devices}
            updateDeviceListBySN={updateDeviceListBySN}
            loading={loading}
            getWarrantyInfo={getWarrantyInfo}
            refreshDeviceList={refreshDeviceList}
            getDeviceListWarranty={getDeviceListWarranty}
          ></Dashboard>
          <ModalLoader shell={shell} stack={stack}></ModalLoader>
        </>
      </ModalContextProvider>
    </div>
  )
}

export default DeviceInfoProvider
