import styled from 'styled-components'
import Tokens from '@veneer/tokens'

export const Section = styled.div`
  color: ${({ theme }) => theme.colorForeground};
  padding: calc(${Tokens.space8} + ${Tokens.space2})
    calc(${Tokens.space8} + ${Tokens.space2});
  &.p-b-28 {
    padding-bottom: ${Tokens.space7};
  }
  z-index: 1;
  h2 {
    color: ${({ theme }) => theme.colorForeground};
    margin-bottom: ${Tokens.space5};
  }
  h3,
  h4 {
    color: ${({ theme }) => theme.colorForeground};
    margin-bottom: ${Tokens.space5};
    margin-top: ${Tokens.space5};
  }
  p {
    margin: ${Tokens.space1} 0px;
  }

  ul {
    margin: ${Tokens.space4} ${Tokens.space8};
  }

  li {
    padding: 2px 0 10px;
    list-style-type: none;
  }

  a {
    cursor: pointer;
  }
`
