import { useContext } from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import {
  MethoneAPIClient,
  MethoneConfig,
  SubscriptionInfo,
  WarrantyInfo
} from '@jarvis/web-support-commons'
import { CoptorAuth } from '@jarvis/web-support-commons/dist/methone/authorization'
import { IProfile } from '@jarvis/web-support-commons/dist/methone'
import { LocalizationType } from '../types/localization'
import { NavigationType } from '../types/shell'
import IFeatureFlag from '../types/featureFlag'
import { IMethoneDevicesResponse } from '@jarvis/web-device-cache/dist/methone/types'
import { IGetDeviceInfoResponse } from '@jarvis/web-support-commons/dist/methone/devices/types/device'
import { StoreStateType } from '../types/store'
import { IData } from './useShared'

export interface IMethone {
  MethoneClient: MethoneAPIClient
  MethoneConfig: MethoneConfig
  MethoneWinClient: IMethoneWinClient
}

export interface IMethoneClient {
  MethoneClient: MethoneAPIClient
  MethoneConfig: MethoneConfig
}

interface WarrantyData extends SubscriptionInfo, WarrantyInfo {}

interface IMethoneWinClient {
  getDeviceImage: (productNumber, serialNumber) => Promise<string>
  getDeviceId: (SerialNumber) => Promise<number>
  confirmWarranty: ({
    SerialNumber,
    ProductNumber,
    Locale,
    CountryCode
  }) => Promise<WarrantyData>
  setAuth: (val) => void
  getDevices: ({
    ignoreDeviceChanges,
    enableStratusCall,
    jobStartViaMFE,
    jobStatusCheckInterval,
    cache
  }) => Promise<IMethoneDevicesResponse | string>
  getDevicesCache: () => Promise<string>
  getWarrantyInfo: (param) => Promise<any>
  refershDevices: (
    deviceListEnabled: boolean,
    jobStatusCheckInterval: number
  ) => Promise<IMethoneDevicesResponse>
  getIsStartusLoadCompleted: (param: boolean) => Promise<any>
  editDeviceNickName: (deviceId, nickname) => Promise<any>
  getOID: (productNumber, serialNumber) => Promise<IGetDeviceInfoResponse>
  getStratusDevices: () => Promise<IMethoneDevicesResponse | string>
  getDevicesFromCache: () => Promise<IMethoneDevicesResponse | string>
  startAsyncJob: () => Promise<string>
}

interface IAuthProvider {
  setAuthToken: () => Promise<CoptorAuth | string>
  refreshToken: () => Promise<CoptorAuth | string>
  checkIsLoggedIn: () => Promise<boolean>
  checkAuth: () => Promise<boolean>
  setIsSignedIn: (isSignedIn: boolean) => void
  isSignedIn: boolean
  auth: CoptorAuth
  profile: IProfile
  logout: () => void
  abortAuthCall: () => void
}

interface IEnvironment {
  platform: string
  isNative: boolean
  isMobile: boolean
}

interface ISharedProvider {
  data: IData
  setData: (val: any) => void
}

type Mode = 'light' | 'dark' | 'contrast'
type Direction = 'rtl' | 'ltr' | 'auto'

interface IThemeProvider {
  dir: Direction
  mode: Mode
}

interface IContext {
  store?: StoreStateType
  sharedProvider?: ISharedProvider
  methoneProvider?: IMethone
  authProvider?: IAuthProvider
  themeProvider?: IThemeProvider
  localization?: LocalizationType
  environment?: IEnvironment
  navigation?: NavigationType
  featureFlag?: IFeatureFlag
  dir?: string
  routersProvider?: any
}

const useContextAPI = () => {
  const {
    store,
    sharedProvider,
    methoneProvider,
    authProvider,
    themeProvider,
    localization,
    environment,
    navigation,
    featureFlag,
    dir,
    routersProvider
  } = useContext<IContext>(RootContext)
  return {
    store,
    sharedProvider,
    methoneProvider,
    authProvider,
    themeProvider,
    localization,
    environment,
    navigation,
    featureFlag,
    dir,
    routersProvider
  }
}

export default useContextAPI
