/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, forwardRef } from 'react'
import { getSupportConsent } from '@jarvis/web-support-commons/dist/privacy'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import CustomImage from '@veneer/core/dist/scripts/custom_image'
import * as S from '../SupportHomeVA/styles'
import { VASmileBlue } from '../../../assets/images'
import { VAChat } from '../VAChat/index'
import { VAType } from '../../Modals/types/index'
import { getVAResources, IVAResource } from '../../../vaJsonParser'
import { TestIDPrefix, formatAutoId } from '../../../utils'
import {
  filterLinks,
  isEmpty,
  calcItemWidth,
  JudgeDisplayLeftOrRight
} from '../utils'
import { genTestId } from '@jarvis/react-hpx-support-shared/dist/utils'
import useVA from '../../../hooks/useVA'

const VATypes = [
  VAType.PrinterAndInkSupport,
  VAType.HPAllInPlanSupportPeople,
  VAType.VADiagnostics
]

const VABoxContainerLeft = (
  listLinks: IVAResource[],
  onChangeContextTitle,
  categoryType
) => {
  let displayItems = JudgeDisplayLeftOrRight(listLinks)
  if (categoryType === VAType.HPAllInPlanSupportPeople) displayItems = 4
  return (
    <S.VABoxContainerWrapper calcItemWidth={calcItemWidth(listLinks)}>
      {isEmpty(listLinks) &&
        listLinks.map(
          (item: IVAResource, index: number) =>
            index <= displayItems && (
              <S.VABoxLeft key={item.context}>
                <S.VABoxRow>
                  <S.VABoxIconCol>{item.iconType}</S.VABoxIconCol>
                  <S.VABoxTextCol>
                    <S.VABoxColAction className="caption">
                      <S.VALinkButton
                        small={true}
                        appearance="ghost"
                        trackId={genTestId(TestIDPrefix, `${item.name}`)}
                        trackCategory={`SupportDetailVA`}
                        tabIndex={0}
                        aria-label={item.title}
                        className="link"
                        id={formatAutoId(
                          `VAModal_list_issueItem_${item.context}`
                        )}
                        onClick={() => onChangeContextTitle(item.context)}
                      >
                        {item.title}
                      </S.VALinkButton>
                    </S.VABoxColAction>
                  </S.VABoxTextCol>
                </S.VABoxRow>
              </S.VABoxLeft>
            )
        )}
    </S.VABoxContainerWrapper>
  )
}

const VABoxContainerRight = (
  listLinks: IVAResource[],
  onChangeContextTitle,
  categoryType
) => {
  let displayItems = JudgeDisplayLeftOrRight(listLinks) + 1
  if (categoryType === VAType.HPAllInPlanSupportPeople) displayItems = 5
  return (
    <S.VABoxContainerWrapper>
      <S.VABoxContainerRightWrapper>
        {isEmpty(listLinks) &&
          listLinks.map(
            (item: IVAResource, index: number) =>
              index >= displayItems && (
                <S.VABoxLeft key={item.context}>
                  <S.VABoxRow>
                    <S.VABoxIconCol>{item.iconType}</S.VABoxIconCol>
                    <S.VABoxTextCol>
                      <S.VABoxColAction className="caption">
                        <S.VALinkButton
                          small={true}
                          appearance="ghost"
                          aria-label={item.title}
                          trackId={genTestId(
                            TestIDPrefix,
                            `${item.name.toLowerCase()}`
                          )}
                          trackCategory={`SupportDetailVA`}
                          tabIndex={0}
                          className="link"
                          id={formatAutoId(
                            `VAModal_list_issueItem_${item.context}`
                          )}
                          onClick={() => onChangeContextTitle(item.context)}
                        >
                          {item.title}
                        </S.VALinkButton>
                      </S.VABoxColAction>
                    </S.VABoxTextCol>
                  </S.VABoxRow>
                </S.VABoxLeft>
              )
          )}
      </S.VABoxContainerRightWrapper>
    </S.VABoxContainerWrapper>
  )
}

const iconsMap = {
  VASmileBlue: () => {
    return (
      <CustomImage viewBox="0 0 36 36" role="img" size={36}>
        <VASmileBlue />
      </CustomImage>
    )
  }
}

const VAContainer = (props) => {
  const { t } = useI18n()
  const { listLinks } = props
  const { getVAConfig } = useVA()

  const onChangeContextTitle = async (query = '') => {
    const { getBotLaunchUrl } = getVAConfig(props)
    await getBotLaunchUrl(query, false)
  }

  return (
    <>
      {!props?.device?.IsChromebook && (
        <>
          <S.VAHeaderWrapper
            tabIndex={'-1'}
            id={formatAutoId('VAModal_header_title')}
          >
            <span className="caption">
              {props?.config?.printerVADescContext
                ? props?.config?.printerVADescContext
                : t('common.selectVAOptionsBelow')}
            </span>
          </S.VAHeaderWrapper>
          <S.VABodyWrapper tabIndex={-1}>
            {VABoxContainerLeft(listLinks, onChangeContextTitle, props?.type)}
            {!calcItemWidth(listLinks) &&
              VABoxContainerRight(listLinks, onChangeContextTitle, props?.type)}
          </S.VABodyWrapper>
          <S.VAFooterWrapper>
            <S.VAFooterLine />
            <S.VAFooterIssueText
              tabIndex={'-1'}
              id={formatAutoId('VAModal_footer_issue_text')}
            >
              <S.VAFooterIssueStrong className="caption-medium">
                {t('common.haveDifferentIssue')}
              </S.VAFooterIssueStrong>
            </S.VAFooterIssueText>
            <S.VAFooterActions>
              <S.VAColImgAndDesc>
                <S.VAColImg>{iconsMap.VASmileBlue()}</S.VAColImg>
                <S.VAColDesc
                  tabIndex={'-1'}
                  id={formatAutoId('VAModal_footer_issue_description')}
                >
                  <span className="caption">
                    {t('common.ifYourIssueIsntListVAOfferMoretourbleshooting')}
                  </span>
                </S.VAColDesc>
              </S.VAColImgAndDesc>
              <S.VAColButton>
                <S.VAButton
                  appearance="secondary"
                  className="secondary"
                  small={true}
                  trackId={genTestId(
                    TestIDPrefix,
                    `startVirtualAssistantButton`
                  )}
                  trackCategory={`SupportDetailVA`}
                  id={formatAutoId('VAModal_startVirtualAssistantButton')}
                  onClick={() => onChangeContextTitle()}
                >
                  {t('common.chatNow')}
                </S.VAButton>
              </S.VAColButton>
            </S.VAFooterActions>
          </S.VAFooterWrapper>
        </>
      )}
    </>
  )
}

// eslint-disable-next-line react/display-name
const SupportDeviceDetailVA = forwardRef((props: any) => {
  const { t } = useI18n()
  const { getVAConfig } = useVA()
  const { config, type, device } = props
  const [listLinks, setListLinks] = useState<IVAResource[]>([])

  useEffect(() => {
    const fetchData = async () => {
      let categoryType = type
      const productType = props?.device?.ProductType
      // ProductType 1 means it is printer machine.
      if (Number(productType) === 1 && type === VAType.VADiagnostics) {
        categoryType = VAType.VAPrinterDiagnostics
      }
      const productSeriesOID = device?.ProductSeriesOID
      const data = (await getVAResources(t)) as IVAResource[]
      setListLinks(filterLinks(data, categoryType, productSeriesOID))
    }
    if (!config?.[type]?.isOpen && !device?.IsChromebook) fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, device?.IsChromebook])

  useEffect(() => {
    ;(async () => {
      const optin = await getSupportConsent()
      // If current device is Chromebook, we don't need to show guided troubleshooting data list in VA dialog, will just directly open VA conversation page.
      if (device?.IsChromebook && !config?.[type]?.isOpen) {
        const { getBotLaunchUrl } = getVAConfig(props)
        await getBotLaunchUrl('', false, optin)
      } else {
        const { updateModal } = props
        const VA = {
          hide: false
        }
        updateModal(VAType.VAFlag, { ...VA })
      }
    })()
  }, [device?.IsChromebook, config?.[type]?.isOpen])

  const restProps = () => {
    return {
      ...props,
      listLinks: listLinks
    }
  }

  return (
    <>
      {config &&
        Object.keys(config)?.map((key) => (
          <>
            {!config?.[key]?.isOpen && key == type && (
              <VAContainer {...restProps()} />
            )}
          </>
        ))}
      {VATypes?.map((key) => (
        <>
          {config?.[key]?.vaUrl && (
            <S.VaChatContainer hide={type !== key}>
              <VAChat type={type} vaUrl={config?.[key]?.vaUrl} />
            </S.VaChatContainer>
          )}
        </>
      ))}
    </>
  )
})

export default React.memo(SupportDeviceDetailVA)
