import React from 'react'
import VABlack from './VABlack.svg'
import VABlue from './VABlue.svg'
import support from './support.svg'
import fiveG from './5G.svg'
const closeIcon = () => (
  <path d="M6 6.84961L1.02539 11.8242C0.908203 11.9414 0.767578 12 0.603516 12C0.431641 12 0.287109 11.9434 0.169922 11.8301C0.0566406 11.7129 0 11.5684 0 11.3965C0 11.2324 0.0585938 11.0918 0.175781 10.9746L5.15039 6L0.175781 1.02539C0.0585938 0.908203 0 0.765625 0 0.597656C0 0.515625 0.015625 0.4375 0.046875 0.363281C0.078125 0.289062 0.121094 0.226563 0.175781 0.175781C0.230469 0.121094 0.294922 0.078125 0.369141 0.046875C0.443359 0.015625 0.521484 0 0.603516 0C0.767578 0 0.908203 0.0585938 1.02539 0.175781L6 5.15039L10.9746 0.175781C11.0918 0.0585938 11.2344 0 11.4023 0C11.4844 0 11.5605 0.015625 11.6309 0.046875C11.7051 0.078125 11.7695 0.121094 11.8242 0.175781C11.8789 0.230469 11.9219 0.294922 11.9531 0.369141C11.9844 0.439453 12 0.515625 12 0.597656C12 0.765625 11.9414 0.908203 11.8242 1.02539L6.84961 6L11.8242 10.9746C11.9414 11.0918 12 11.2324 12 11.3965C12 11.4785 11.9844 11.5566 11.9531 11.6309C11.9219 11.7051 11.8789 11.7695 11.8242 11.8242C11.7734 11.8789 11.7109 11.9219 11.6367 11.9531C11.5625 11.9844 11.4844 12 11.4023 12C11.2344 12 11.0918 11.9414 10.9746 11.8242L6 6.84961Z" />
)

const maximize = () => (
  <path d="M1.76953 12C1.53516 12 1.31055 11.9531 1.0957 11.8594C0.880859 11.7617 0.691406 11.6328 0.527344 11.4727C0.367188 11.3086 0.238281 11.1191 0.140625 10.9043C0.046875 10.6895 0 10.4648 0 10.2305V1.76953C0 1.53516 0.046875 1.31055 0.140625 1.0957C0.238281 0.880859 0.367188 0.693359 0.527344 0.533203C0.691406 0.369141 0.880859 0.240234 1.0957 0.146484C1.31055 0.0488281 1.53516 0 1.76953 0H10.2305C10.4648 0 10.6895 0.0488281 10.9043 0.146484C11.1191 0.240234 11.3066 0.369141 11.4668 0.533203C11.6309 0.693359 11.7598 0.880859 11.8535 1.0957C11.9512 1.31055 12 1.53516 12 1.76953V10.2305C12 10.4648 11.9512 10.6895 11.8535 10.9043C11.7598 11.1191 11.6309 11.3086 11.4668 11.4727C11.3066 11.6328 11.1191 11.7617 10.9043 11.8594C10.6895 11.9531 10.4648 12 10.2305 12H1.76953ZM10.2012 10.7988C10.2832 10.7988 10.3594 10.7832 10.4297 10.752C10.5039 10.7207 10.5684 10.6777 10.623 10.623C10.6777 10.5684 10.7207 10.5059 10.752 10.4355C10.7832 10.3613 10.7988 10.2832 10.7988 10.2012V1.79883C10.7988 1.7168 10.7832 1.64062 10.752 1.57031C10.7207 1.49609 10.6777 1.43164 10.623 1.37695C10.5684 1.32227 10.5039 1.2793 10.4297 1.24805C10.3594 1.2168 10.2832 1.20117 10.2012 1.20117H1.79883C1.7168 1.20117 1.63867 1.2168 1.56445 1.24805C1.49414 1.2793 1.43164 1.32227 1.37695 1.37695C1.32227 1.43164 1.2793 1.49609 1.24805 1.57031C1.2168 1.64062 1.20117 1.7168 1.20117 1.79883V10.2012C1.20117 10.2832 1.2168 10.3613 1.24805 10.4355C1.2793 10.5059 1.32227 10.5684 1.37695 10.623C1.43164 10.6777 1.49414 10.7207 1.56445 10.752C1.63867 10.7832 1.7168 10.7988 1.79883 10.7988H10.2012Z" />
)

const minimize = () => (
  <path d="M0.597656 2C0.515625 2 0.4375 1.98438 0.363281 1.95312C0.292969 1.92188 0.230469 1.87891 0.175781 1.82422C0.121094 1.76953 0.078125 1.70703 0.046875 1.63672C0.015625 1.5625 0 1.48438 0 1.40234C0 1.32031 0.015625 1.24414 0.046875 1.17383C0.078125 1.09961 0.121094 1.03516 0.175781 0.980469C0.230469 0.921875 0.292969 0.876953 0.363281 0.845703C0.4375 0.814453 0.515625 0.798828 0.597656 0.798828H11.4023C11.4844 0.798828 11.5605 0.814453 11.6309 0.845703C11.7051 0.876953 11.7695 0.921875 11.8242 0.980469C11.8789 1.03516 11.9219 1.09961 11.9531 1.17383C11.9844 1.24414 12 1.32031 12 1.40234C12 1.48438 11.9844 1.5625 11.9531 1.63672C11.9219 1.70703 11.8789 1.76953 11.8242 1.82422C11.7695 1.87891 11.7051 1.92188 11.6309 1.95312C11.5605 1.98438 11.4844 2 11.4023 2H0.597656Z" />
)

const disableMinimize = () => (
  <path
    d="M0.597656 2C0.515625 2 0.4375 1.98438 0.363281 1.95312C0.292969 1.92188 0.230469 1.87891 0.175781 1.82422C0.121094 1.76953 0.078125 1.70703 0.046875 1.63672C0.015625 1.5625 0 1.48438 0 1.40234C0 1.32031 0.015625 1.24414 0.046875 1.17383C0.078125 1.09961 0.121094 1.03516 0.175781 0.980469C0.230469 0.921875 0.292969 0.876953 0.363281 0.845703C0.4375 0.814453 0.515625 0.798828 0.597656 0.798828H11.4023C11.4844 0.798828 11.5605 0.814453 11.6309 0.845703C11.7051 0.876953 11.7695 0.921875 11.8242 0.980469C11.8789 1.03516 11.9219 1.09961 11.9531 1.17383C11.9844 1.24414 12 1.32031 12 1.40234C12 1.48438 11.9844 1.5625 11.9531 1.63672C11.9219 1.70703 11.8789 1.76953 11.8242 1.82422C11.7695 1.87891 11.7051 1.92188 11.6309 1.95312C11.5605 1.98438 11.4844 2 11.4023 2H0.597656Z"
    color="gray"
  />
)
const HPOne = () => (
  <>
    <path d="M14.1421 27.8972C14.0031 27.8972 13.8642 27.8972 13.7284 27.8908L16.5768 20.0565H20.4989C21.1873 20.0565 21.9421 19.5258 22.1789 18.8814L25.2705 10.3868C25.7758 8.99684 24.98 7.8596 23.5021 7.8596H18.061L13.4947 20.4072L10.9084 27.5212C4.76944 26.0618 0.203125 20.5398 0.203125 13.9502C0.203125 7.36048 4.50418 2.1576 10.3747 0.514917L7.70313 7.85644L3.26313 20.0565H6.21891L9.9926 9.68866H12.2126L8.43892 20.0565H11.3915L14.9094 10.3868C15.4147 8.99684 14.6189 7.8596 13.141 7.8596H10.6558L13.5137 0.0157949C13.7221 0.00631792 13.9305 0 14.1389 0C21.841 0 28.0842 6.24535 28.0842 13.9502C28.0842 21.655 21.841 27.9003 14.1389 27.9003L14.1421 27.8972ZM22.58 9.68234H20.36L17.2494 18.218H19.4694L22.58 9.68234Z" />
    <path d="M36.4989 18.4307C34.0326 18.4307 32.0273 16.4247 32.0273 13.9575C32.0273 11.4903 34.0326 9.48438 36.4989 9.48438C38.9652 9.48438 40.9705 11.4903 40.9705 13.9575C40.9705 16.4247 38.9652 18.4307 36.4989 18.4307ZM36.4989 10.8491C34.7842 10.8491 33.3916 12.2422 33.3916 13.9575C33.3916 15.6729 34.7842 17.066 36.4989 17.066C38.2137 17.066 39.6063 15.6697 39.6063 13.9575C39.6063 12.2453 38.2105 10.8491 36.4989 10.8491Z" />
    <path d="M51.0632 17.8851C51.0632 17.8851 51.0632 17.882 51.0632 17.8788V9.99708H51.0569C51.0569 9.91495 51.038 9.83597 50.9874 9.77595C50.9369 9.71909 50.8674 9.6875 50.7916 9.6875H49.9769C49.8285 9.6875 49.6706 9.80754 49.6611 9.99708H49.658V16.1129L44.0685 9.90547L44.0243 9.85177C43.9611 9.77279 43.8948 9.69382 43.759 9.69382H42.9159C42.7643 9.69382 42.6001 9.81386 42.5938 10.0066V10.016V17.9072H42.6001C42.6001 17.9894 42.619 18.0683 42.6695 18.1252C42.7201 18.1821 42.7895 18.2136 42.8653 18.2136H43.6801C43.8285 18.2136 43.9864 18.0936 43.9959 17.9041V17.8914V11.9178L49.5348 18.0683C49.6011 18.141 49.6706 18.2105 49.8032 18.2105H50.7159C50.8769 18.2105 51.0474 18.0904 51.0569 17.8946V17.8851H51.0632Z" />
    <path d="M59.492 14.6464V14.6401C59.492 14.6401 59.5077 14.6401 59.5172 14.6401C59.5867 14.6401 59.6562 14.6148 59.7067 14.5706C59.7635 14.52 59.7951 14.4505 59.7951 14.3747V13.5565C59.7951 13.4081 59.6783 13.2438 59.492 13.2438V13.2375H54.2056V11.0862H59.4951V11.0799C59.4951 11.0799 59.5109 11.0799 59.5172 11.0799C59.5867 11.0799 59.6562 11.0546 59.7067 11.0135C59.7635 10.963 59.7951 10.8935 59.7951 10.8177V10.0027C59.7951 9.85418 59.6814 9.69623 59.4951 9.69307V9.68359H53.1762V9.70255C53.053 9.72782 52.9393 9.82575 52.9204 9.98054H52.9141V10.0121C52.9141 10.0121 52.9141 10.0153 52.9141 10.0184V17.916H52.9235C52.9235 17.9918 52.9456 18.0613 52.9898 18.115C53.0372 18.1687 53.1035 18.2003 53.1762 18.2034H59.4951V18.1971C59.4951 18.1971 59.5109 18.1971 59.5204 18.1971C59.593 18.1971 59.6593 18.1718 59.7099 18.1276C59.7667 18.0771 59.7983 18.0076 59.7983 17.9318V17.0915C59.7983 16.9272 59.6688 16.8008 59.4951 16.8008V16.7945H54.2088V14.6432H59.4951L59.492 14.6464Z" />
  </>
)
const FiveGSVG = () => (
  <path d="M5.42 15.26C8.34 15.26 10.34 13.2 10.34 9.92C10.34 6.86 8.48 5 6.02 5C4.68 5 3.72 5.44 2.98 6.3L3.28 2.46H9.2C9.34 2.46 9.42 2.38 9.42 2.24V1.22C9.42 1.08 9.34 0.999999 9.2 0.999999H1.86C1.72 0.999999 1.62 1.08 1.62 1.22L1.16 8.38C1.16 8.52 1.22 8.6 1.36 8.6H2.62C2.74 8.6 2.84 8.54 2.86 8.4C3.2 7.12 4.1 6.38 5.52 6.38C7.32 6.38 8.32 7.66 8.32 10.04C8.32 12.46 7.2 13.9 5.4 13.9C3.76 13.9 2.84 12.88 2.74 11.34C2.72 11.2 2.64 11.12 2.5 11.12H1.06C0.92 11.12 0.82 11.2 0.84 11.36C1.12 13.72 2.6 15.26 5.42 15.26ZM18.8347 15.26C22.8547 15.26 25.0747 12.72 25.0547 8.02C25.0547 7.86 24.9747 7.76 24.8347 7.76H18.9347C18.7947 7.76 18.7147 7.84 18.7147 7.98V8.94C18.7147 9.08 18.7947 9.16 18.9347 9.16H23.0747C23.0347 12 21.5947 13.8 18.9147 13.8C16.1947 13.8 14.3747 11.58 14.3747 8.02C14.3747 4.38 16.1347 2.2 18.8347 2.2C20.8947 2.2 22.2947 3.26 22.7747 5.26C22.7947 5.4 22.8947 5.46 23.0147 5.46H24.5347C24.6747 5.46 24.7547 5.36 24.7147 5.22C24.0147 2.34 21.9347 0.739999 18.8147 0.739999C15.0347 0.739999 12.4347 3.54 12.4347 8C12.4347 12.4 15.0947 15.26 18.8347 15.26Z" />
)

const VASmileBlue = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <g clipPath="url(#clip0_22198_25671)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 36C27.9412 36 36 27.9412 36 18C36 8.05888 27.9412 0 18 0C8.05888 0 0 8.05888 0 18C0 21.776 1.16265 25.2802 3.14948 28.1746C3.15668 28.9076 2.94088 29.7252 2.76298 30.399C2.70264 30.6276 2.64664 30.8398 2.6052 31.0264C2.54574 31.2942 2.41896 31.6562 2.27746 32.0604C1.85589 33.264 1.30381 34.8406 2.0131 35.408C3.19732 36.3554 4.2631 35.7632 4.2631 35.7632C4.2631 35.7632 6.86844 34.3422 7.57898 33.8684C7.90868 33.6486 8.3404 33.5054 8.77946 33.4622C11.4762 35.0738 14.63 36 18 36Z"
          fill="#0C95D3"
        />
        <path
          d="M8.69482 24.8644C8.69482 24.8644 12.451 29.0824 18.4878 28.8188C24.5246 28.5552 27.61 24.8644 27.61 24.8644"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_22198_25671">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
)

export {
  disableMinimize,
  closeIcon,
  maximize,
  minimize,
  VABlue,
  VABlack,
  support,
  fiveG,
  HPOne,
  FiveGSVG,
  VASmileBlue
}
