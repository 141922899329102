import { useState, useEffect } from 'react'
import { ensureWeb, JARVIS_ENVIRONMENT } from '../../src/utils'

/**
 * Judgement different platforms
 * @returns obj
 */
const usePlatforms = () => {
  const [environment, setEnvironment] = useState(null)

  useEffect(() => {
    const getPlatform = async () => {
      const { platform, isNative } = await ensureWeb()
      const isMobile = [
        JARVIS_ENVIRONMENT.ANDROID,
        JARVIS_ENVIRONMENT.IOS
      ].includes(platform)
      setEnvironment({ platform, isNative, isMobile })
    }
    getPlatform()
  }, [])

  return {
    environment
  }
}

export default usePlatforms
