export const printerRegedit = {
  ShowPT_USB2Wireless: [
    5304875, 5304881, 5295960, 5203208, 5199461, 5305049, 5169038, 5165422,
    5367603, 5390307, 5390318, 6924026, 6583033, 6617267, 6617253, 7172306,
    7172328, 7234983, 5447939, 7172321, 7127198, 5447926, 7317523, 7532065,
    7538683, 7588794, 5447920, 5447930, 8109015, 5367611, 5367618, 5385103,
    5398139, 7169963
  ],
  ShowPT_Copy: [
    5304875, 5304881, 5285089, 5157551, 5295960, 5203208, 5199461, 5305049,
    5357283, 5169038, 5202284, 5165422, 5165417, 5367603, 5390307, 6924026,
    6583033, 6617267, 6617253, 7174550, 7172306, 7172328, 7234983, 5447939,
    7172321, 7127198, 5447926, 7317523, 7532065, 7538683, 7588794, 5447920,
    5447930, 8109015, 7840162, 5367611, 5367618, 5385103, 5398139, 7169963
  ],
  ShowPT_CarriageJam: [
    5304875, 5304881, 5157543, 5285089, 5157551, 5295960, 5203208, 5199461,
    5305049, 5357283, 5169038, 5202284, 5202295, 5165422, 5165417, 5367603,
    5390307, 5390318, 6924026, 6583033, 6617267, 6617253, 7174529, 7174550,
    7172306, 7172328, 7234983, 5447939, 7172321, 7127198, 5447926, 7317523,
    7532065, 7538683, 7588794, 5447920, 5447930, 8109015, 7840162, 5367611,
    5367618, 5385103, 5398139, 7169963
  ],
  ShowPT_FaxErrors: [
    5153788, 5153866, 5081829, 5305049, 5357283, 5390307, 5367603, 6924026,
    6617267, 6617253, 7172328, 7172321, 7127198, 7317523, 5447930, 5367611,
    5367618, 5385103, 5398139, 7169963
  ]
}
