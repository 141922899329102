import { useEffect, useState } from 'react'

const useRefresh = () => {
  const component = {
    cases: false,
    warranty: false
  }

  const [refreshList, setRefreshList] = useState(component)

  useEffect(() => {
    console.log('refreshList')
    console.log(refreshList)
  }, [refreshList])

  const refreshComponent = (refresh) => {
    console.log('refreshComponent')
    console.log(refresh)
    setRefreshList({ ...refreshList, ...refresh })
  }

  return {
    refreshList,
    refreshComponent
  }
}

export default useRefresh
